import React, { useState } from "react";
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const sOptions = [
  { label: "Facebook", value: "Facebook" },
  { label: "Twitter", value: "Twitter" },
  { label: "Instagram", value: "Instagram" },
];
const ContactSmItem = ({
  contactsm,
  showConfModal,
  finishEditing,
  inputs,
  selectValues,
}) => {
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const [newContactSm, setNewContactSm] = useState(contactsm);
  const defaultSelects = contactsm.contacts.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [newContacts, setNewContacts] = useState(defaultSelects);
  const invalidName = !newContactSm.name.trim() && edit;
  return (
    <tr>
      <th scope="row">{contactsm.id}</th>
      {inputs.map((c, i) => {
        if (!edit) return <td key={i}>{contactsm[c.field]}</td>;
        else
          return (
            <td key={i}>
              {c.type != "select" ? (
                <input
                  value={newContactSm[c.field]}
                  type={c.type}
                  className="form-control shadow-none"
                  onChange={(e) =>
                    setNewContactSm({
                      ...newContactSm,
                      [c.field]: e.target.value,
                    })
                  }
                />
              ) : (
                <Select
                  options={sOptions}
                  defaultValue={{
                    label: contactsm.smtype,
                    value: contactsm.smtype,
                  }}
                  onChange={(value) => {
                    setNewContactSm({
                      ...newContactSm,
                      [c.field]: value ? value.value : null,
                    });
                  }}
                />
              )}
            </td>
          );
      })}
      <td>
        {!edit ? (
          newContactSm.contacts.map((c, i) => {
            return (
              <React.Fragment key={i}>
                <Badge
                  pill
                  bg="primary"
                  className="cursor-pointer"
                  onClick={() => navigate(`/contactbooks/${c.contactbook}`)}
                >
                  {c.name}
                </Badge>{" "}
              </React.Fragment>
            );
          })
        ) : (
          <Select
            defaultValue={newContacts}
            options={selectValues}
            isMulti
            onChange={(values) => {
              setNewContacts(values);
            }}
          />
        )}
      </td>
      <td className="d-flex align-items-center justify-content-center">
        <button
          disabled={invalidName}
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else finishEditing(newContactSm, newContacts, contactsm);
          }}
        >
          {!edit ? "Edit" : "Finish edit"}
        </button>
        <button
          className={`btn btn-${!edit ? "danger" : "secondary"} shadow-none`}
          style={{ marginLeft: "5px" }}
          onClick={() => {
            if (!edit) showConfModal();
            else {
              setEdit(false);
              setNewContactSm(contactsm);
            }
          }}
        >
          {!edit ? "Delete" : "Cancel"}
        </button>
      </td>
    </tr>
  );
};

export default ContactSmItem;
