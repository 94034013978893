import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fetchHead from "../Utils/fetchHead";
import uri from "../Utils/uri";
import Loading from "../components/Loading";
import ErrorBox from "../components/ErrorBox";
import AddModal from "../components/AddModal";
import ConfirmModal from "../components/ConfirmModal";
import { toast } from "react-toastify";
import SourceItem from "../components/SourceItem";
import { toDateTime } from "../Utils/utils";
const Sources = () => {
  const navigate = useNavigate();
  const currID = useRef(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalErr, setModalErr] = useState(null);
  const [selectValues, setSelectValues] = useState([]);
  const [data, setData] = useState([]);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const now = new Date();
  const [modalInputs, setModalInputs] = useState([
    { title: "Name", type: "text", field: "name", value: "", optional: false },
    {
      title: "Date",
      type: "datetime-local",
      field: "date",
      value: toDateTime(new Date()),
      optional: false,
    },
    {
      title: "Contact Book",
      type: "select",
      field: "contactbook",
      value: "NULL",
      optional: true,
    },
  ]);

  const getSources = async () => {
    try {
      setError(null);
      const URI = uri("/sources");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setData(res.data.sources);
        setSelectValues(res.data.contactbooks);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const addSource = async (body) => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      let dataToSend = {};
      body.forEach((d) => {
        if (d.value != "NULL") dataToSend[d.field] = d.value;
      });

      const URI = uri("/sources/add");
      const req = await axios.post(URI, dataToSend, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        toast.success("Source added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setModal(false);
        getSources();
      } else {
        setModalErr(res.data);
        setLoading(false);
      }
    } catch (err) {
      setModalErr("An error has occured!");
      setLoading(false);
    }
  };

  const deleteSource = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const req = await axios.delete(
        uri(`/sources/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Source deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getSources();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  const editSource = async (newSource) => {
    try {
      setLoading(true);
      setError(null);
      //(uri(`/countries/${currID.current}`));
      let _newSource = {
        date: newSource.date,
        id: newSource.id,
        name: newSource.name,
      };
      if (newSource.cbId != "NULL") {
        _newSource = { ..._newSource, contactbook: newSource.cbId };
      }

      const req = await axios.put(
        uri(`/sources/${_newSource.id}`),
        _newSource,
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Source edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getSources();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getSources();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false })}
        onConfirm={() => deleteSource()}
      />
      <AddModal
        show={modal}
        inputs={modalInputs}
        onHide={() => setModal(false)}
        title={"Add Source"}
        onConfirm={addSource}
        selectData={selectValues}
        size="lg"
        err={modalErr}
      />
      <p className="display-5 text-center">Sources</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary shadow-none"
          onClick={() => setModal(true)}
        >
          Add Source
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">date</th>
              <th scope="col">contact book</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              const cbOptions = selectValues.filter((x) => x.id != d.cbId);
              return (
                <SourceItem
                  key={i}
                  finishEditing={editSource}
                  source={d}
                  selectData={cbOptions}
                  showConfModal={() => {
                    currID.current = d.id;
                    setConfModal({
                      title: `Are you sure you want to delete source: ${d.name}`,
                      show: true,
                    });
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sources;
