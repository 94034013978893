import React, { useState } from "react";

const TagItem = ({ tag, showConfModal, finishEditing, inputs }) => {
  const [edit, setEdit] = useState(false);
  const [newTag, setNewTag] = useState(tag);
  return (
    <tr>
      <th scope="row">{tag.id}</th>
      {inputs.map((c, i) => {
        if (!edit) return <td key={i}>{tag[c.field]}</td>;
        else
          return (
            <td key={i}>
              <input
                value={newTag[c.field]}
                type={c.type}
                className="form-control shadow-none"
                onChange={(e) =>
                  setNewTag({ ...newTag, [c.field]: e.target.value })
                }
              />
            </td>
          );
      })}
      <td className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else finishEditing(newTag);
          }}
        >
          {!edit ? "Edit" : "Finish edit"}
        </button>
        <button
          className={`btn btn-${!edit ? "danger" : "secondary"} shadow-none`}
          style={{ marginLeft: "5px" }}
          onClick={() => {
            if (!edit) showConfModal();
            else {
              setEdit(false);
              setNewTag(tag);
            }
          }}
        >
          {!edit ? "Delete" : "Cancel"}
        </button>
      </td>
    </tr>
  );
};

export default TagItem;
