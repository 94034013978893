import React from "react";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toDateTime } from "../Utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";

const ProjectItem = ({ project }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <th scope="row">{project.id}</th>
      <td>
        {/* <FontAwesomeIcon icon={faCircleDot} color={project.color} /> */}
        {project.name}
      </td>
      <td>
        <Badge
          pill
          bg="primary"
          className="cursor-pointer"
          onClick={() => navigate(`/customers/${project.customerID}`)}
        >
          {project.customerName}
        </Badge>
      </td>
      <td>{project.status}</td>
      <td>{project.project_type}</td>
      <td>{toDateTime(new Date(project.starting_date))}</td>
      <td className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => navigate(`/projects/${project.id}`)}
        >
          See more
        </button>
      </td>
    </tr>
  );
};

export default ProjectItem;
