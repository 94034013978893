import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import ReactPaginate from "react-paginate";
import { fetchData, getCurrentUser } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
import DailyReportItem from "./DailyReportItem";

const perPage = 25;
const DailyReports = () => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser(navigate);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const getDailyReports = async () => {
    if (!loading) setLoading(true);
    let URI = `/dailyreports/${pageOffset}`;
    if (!currentUser.superuser) {
      URI = `/dailyreports/${pageOffset}/?userid=${currentUser.id}`;
    }
    const { status, data, msg } = await fetchData(URI);
    if (status) {
      console.log(data);
      let dates = data.reports.map((r) => {
        const d = new Date(r.created_at);
        return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
      });
      console.log(dates);
      let allDates = [...new Set(dates)];
      allDates = allDates.map((dd) => ({ date: dd, reports: [] }));
      data.reports.forEach((report, i) => {
        const reportDate = new Date(report.created_at);
        const reportString = `${reportDate.getFullYear()}-${
          reportDate.getMonth() + 1
        }-${reportDate.getDate()}`;
        const index = allDates.findIndex((v) => {
          return v.date == reportString;
        });
        if (index != -1) allDates[index].reports.push(report);
      });
      setReports(allDates);
      setPageCount(Math.ceil(data.count / perPage));
    } else {
      console.log(msg);
    }
    setLoading(false);
  };

  const handlePageChange = (e) => {
    const pageNum = e.selected + 1;
    setPageOffset(pageNum);
  };

  useEffect(() => {
    getDailyReports();
  }, [pageOffset, perPage]);

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <p className="display-6">Daily Reports</p>
        <button
          className="btn btn-primary mb-3"
          onClick={() => navigate("/dailyreports/add")}
        >
          Add daily report
        </button>
        {reports.map((x, i) => (
          <DailyReportItem
            i={i}
            key={Math.random()}
            date={x.date}
            reports={x.reports}
          />
        ))}

        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageOffset - 1}
        />
      </div>
    );
};

export default DailyReports;
