import React from "react";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const ContactBookItem = ({ contactbook, showConfModal }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <th scope="row">{contactbook.id}</th>
      <td>
        {contactbook.first_name} {contactbook.last_name}
      </td>
      <td>{contactbook.mobile}</td>
      <td>{contactbook.phone}</td>
      <td>{contactbook.email}</td>
      <td>{contactbook.name}</td>
      <td>
        {contactbook.groups.map((c, i) => {
          return (
            <React.Fragment key={i}>
              <Badge pill bg="primary">
                {c.name}
              </Badge>{" "}
            </React.Fragment>
          );
        })}
      </td>
      <td className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => navigate(`/contactbooks/${contactbook.id}`)}
        >
          See More
        </button>
        <button
          onClick={showConfModal}
          style={{ marginLeft: "5px" }}
          className="btn btn-danger shadow-none"
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ContactBookItem;
