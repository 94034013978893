import React, { useState, useEffect } from "react";
import ErrorBox from "../../components/ErrorBox";
import Loading from "../../components/Loading";
import uri from "../../Utils/uri";
import { useNavigate } from "react-router-dom";
import fetchHead from "../../Utils/fetchHead";
import { toDateTime } from "../../Utils/utils";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import Editor from '../../components/Editor';
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Closed", label: "Closed" },
  { value: "Pending", label: "Pending" },
  { value: "Cancelled", label: "Cancelled" },
];

const AddProject = () => {
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
  const [selectOptions, setSelectOptions] = useState({
    customers: [],
    project_types: [],
    users: [],
  });
  const [customer, setCustomer] = useState(null);
  const [manager, setManager] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [details, setDetails] = useState("");
  const [members, setMembers] = useState([]);
  const [status, setStatus] = useState(null);
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const navigate = useNavigate();

  const dataInvalid =
    !name.trim() ||
    !customer ||
    !manager ||
    !projectType ||
    members.length == 0 ||
    !status;

  const getOptions = async () => {
    try {
      const res = await axios.get(uri("/projects/addoptions"), fetchHead());
      if (res.data.status == 200) {
        setSelectOptions({
          customers: res.data.data.customers,
          project_types: res.data.data.project_types,
          users: res.data.data.users,
        });
      } else setError(res.data.data);
      setLoading(false);
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  const addProject = async () => {
    try {
      setLoading(true);
      const dataToSend = {
        members,
        status: status ? status.value : null,
        details: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''"),
        manager: manager ? manager.value : null,
        customer: customer ? customer.value : null,
        name,
        projectType: projectType ? projectType.value : null,
        startingDate: startingDate ? toDateTime(new Date(startingDate)) : null,
        endingDate: endingDate ? toDateTime(new Date(endingDate)) : null,
      };
      const req = await axios.post(
        uri("/projects/add"),
        dataToSend,
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Project created", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/projects");
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="container" style={{ paddingBottom: "100px" }}>
      <p className="display-5 text-center">Add Project</p>
      {error && <ErrorBox type={"warning"} err={error} />}

      <label className="form-label">Name</label>
      <input
        type="email"
        className="form-control"
        value={name}
        onChange={(t) => setName(t.target.value)}
      />

      <label className="form-label mt-3">Customer</label>
      <Select
        isClearable
        value={customer}
        options={selectOptions.customers}
        onChange={(v) => setCustomer(v)}
      />

      <label className="form-label mt-3">Manager</label>
      <Select
        isClearable
        value={manager}
        options={selectOptions.users}
        onChange={(v) => setManager(v)}
      />

      <label className="form-label mt-3">Project Type</label>
      <Select
        isClearable
        value={projectType}
        options={selectOptions.project_types}
        onChange={(v) => setProjectType(v)}
      />

      <label className="form-label mt-3">Details</label>
      <Editor editorState={editorState} setEditorState={setEditorState} />


      <label className="form-label mt-3">Members</label>
      <Select
        isClearable
        isMulti
        value={members}
        options={selectOptions.users}
        onChange={(v) => setMembers(v)}
      />

      <label className="form-label mt-3">Status</label>
      <Select
        isClearable
        value={status}
        options={statusOptions}
        onChange={(v) => setStatus(v)}
      />

      <label className="form-label mt-3">Starting Date</label>
      <DatePicker
        className="form-control"
        selected={startingDate}
        onChange={(d) => setStartingDate(d)}
        showTimeSelect
        dateFormat="Pp"
        showYearDropdown
        isClearable
        scrollableYearDropdown
      />

      <label className="form-label mt-3">Ending Date</label>
      <DatePicker
        className="form-control"
        selected={endingDate}
        onChange={(date) => setEndingDate(date)}
        showTimeSelect
        dateFormat="Pp"
        showYearDropdown
        scrollableYearDropdown
        isClearable
      />

      <button
        disabled={dataInvalid}
        onClick={() => addProject()}
        className="btn btn-primary mt-5 w-100 fs-5 shadow-none"
      >
        Add Project
      </button>
    </div>
  );
};

export default AddProject;
