import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import fetchHead from "../../Utils/fetchHead";
import uri from "../../Utils/uri";
import CustomerItem from "../../components/CustomerItem";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortedBy, setSortedBy] = useState("DESC");
  const navigate = useNavigate();

  const sortData = () => {
    if (sortedBy == "DESC") {
      setSortedBy("ASCE");
      setCustomers(customers.reverse());
    } else {
      setSortedBy("DESC");
      setCustomers(customers.reverse());
    }
  };

  const getCustomers = async () => {
    try {
      if (error) setError(null);
      const req = await axios.get(uri("/customers"), fetchHead());
      const res = req.data;

      if (res.status == 200) {
        setCustomers(res.data);
        //setInitData(myUsers);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      <p className="display-5 text-center">Customers</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary"
          onClick={() => navigate("/customers/create")}
        >
          Add Customer
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Name</th>
              <th scope="col">Company?</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Website</th>
              <th scope="col">
                Date Created{" "}
                <FontAwesomeIcon
                  className="cursor-pointer"
                  onClick={() => sortData()}
                  icon={faSort}
                />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {customers.map((item, i) => {
              return <CustomerItem customer={item} key={i} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Customers;
