import React from "react";
import { useNavigate } from "react-router-dom";
import { toDateTime } from "../Utils/utils";
const UserItem = ({ user }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <th scope="row">{user.id}</th>
      <td>{user.username}</td>
      <td>{`${user.first_name} ${user.last_name}`}</td>
      <td>{toDateTime(new Date(user.date_joined))}</td>
      <td>{user.last_login && toDateTime(new Date(user.last_login))}</td>
      <td>
        <button
          className="btn btn-dark"
          onClick={() => {
            navigate(`/users/${user.id}`);
          }}
        >
          See more
        </button>
      </td>
    </tr>
  );
};

export default UserItem;
