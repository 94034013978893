import React, { Component } from "react";
import Editor, { createEditorStateWithText } from "@draft-js-plugins/editor";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import "./editorStyles.css";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;
const plugins = [staticToolbarPlugin];

export default class SimpleStaticToolbarEditor extends Component {
  state = {
    editorState: this.props.editorState,
  };

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  componentDidMount() {
    this.setState({
      editorState: this.props.editorState,
    });
  }

  focus = () => {
    this.editor.focus();
  };

  render() {
    return (
      <div>
        <div className={"editor"} onClick={this.focus}>
          <Toolbar />
          <Editor
            placeholder="Enter status..."
            editorState={this.props.editorState}
            onChange={this.props.setEditorState}
            plugins={plugins}
            ref={(element) => {
              this.editor = element;
            }}
          />
        </div>
      </div>
    );
  }
}
