import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fetchHead from "../Utils/fetchHead";
import uri from "../Utils/uri";
import Loading from "../components/Loading";
import ErrorBox from "../components/ErrorBox";
import AddModal from "../components/AddModal";
import ConfirmModal from "../components/ConfirmModal";
import { toast } from "react-toastify";
import ContactSmItem from "../components/ContactSmItem";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
const sOptions = [
  { label: "Facebook", value: "Facebook" },
  { label: "Twitter", value: "Twitter" },
  { label: "Instagram", value: "Instagram" },
];
const ContactSm = () => {
  const navigate = useNavigate();
  const currID = useRef(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalErr, setModalErr] = useState(null);
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [smtype, setSmType] = useState({ value: null, label: "" });
  const [selectValues, setSelectValues] = useState([]);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const [smContacts, setSmContacts] = useState([]);
  const [modalInputs, setModalInputs] = useState([
    { title: "Name", type: "text", field: "name", value: "", optional: false },
    { title: "URL", type: "text", field: "url", value: "", optional: true },
    {
      title: "sm type",
      type: "select",
      field: "smtype",
      value: "",
      optional: false,
    },
  ]);

  const dataInvalid = !name.trim() || !smtype.value;

  const getContactSms = async () => {
    try {
      setError(null);
      const URI = uri("/contactsm");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setData(res.data.contactsm);
        setSelectValues(res.data.contactbooks);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const addContactSm = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const dataToSend = {
        name,
        smtype: smtype.value,
        url,
        contacts: smContacts.map((i) => ({ id: i.value })),
      };
      const URI = uri("/contactsm/add");
      const req = await axios.post(URI, dataToSend, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        toast.success("contact sm added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setModal(false);
        getContactSms();
      } else {
        setModalErr(res.data);
        setLoading(false);
      }
    } catch (err) {
      setModalErr("An error has occured!");
      setLoading(false);
    }
  };

  const deleteContactSm = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const req = await axios.delete(
        uri(`/contactsm/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact sm deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getContactSms();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  const editContactSm = async (newContactSm, newContacts, sms) => {
    try {
      setLoading(true);
      setError(null);
      let toDelete = [];
      let toAdd = [];
      sms.contacts.forEach((item, i) => {
        const remained = newContacts.some((x) => x.value == item.id);

        if (!remained) toDelete.push({ ...item, type: "delete" });
      });
      newContacts.forEach((item, i) => {
        const remained = sms.contacts.some((x) => x.id == item.value);
        if (!remained) toAdd.push({ ...item, type: "add" });
      });
      const finalContacts = [...toAdd, ...toDelete];
      const newDataToSend = {
        name: newContactSm.name,
        url: newContactSm.url,
        smtype: newContactSm.smtype,
        contacts: finalContacts,
      };
      const req = await axios.put(
        uri(`/contactsm/${newContactSm.id}`),
        newDataToSend,
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact sm edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getContactSms();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  const addModal = () => {
    return (
      <Modal
        show={modal}
        inputs={modalInputs}
        onHide={() => setModal(false)}
        size={"sm"}
        centered
      >
        <Modal.Body>
          <p>Add Contact SM</p>
          {modalErr && <ErrorBox err={modalErr} type="danger" />}
          <Form.Label>Name</Form.Label>
          <InputGroup>
            <input
              value={name}
              type={"text"}
              className="form-control"
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>

          <Form.Label className="mt-3">URL</Form.Label>
          <InputGroup>
            <input
              value={url}
              type={"text"}
              className="form-control"
              onChange={(e) => setUrl(e.target.value)}
            />
          </InputGroup>

          <Form.Label className="mt-3">Sm Type</Form.Label>
          <Select
            options={sOptions}
            onChange={(e) => {
              if (e) setSmType(e);
              else setSmType({ label: "", value: null });
            }}
            isClearable
          />

          <Form.Label className="mt-3">Contacts</Form.Label>
          <Select
            options={selectValues}
            isMulti
            onChange={(values) => {
              setSmContacts(values);
            }}
          />

          <Modal.Footer>
            <Button variant="danger" onClick={() => setModal(false)}>
              <FontAwesomeIcon icon={faXmark} />
            </Button>
            <Button
              disabled={dataInvalid}
              variant="success"
              onClick={() => {
                addContactSm(data);
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    );
  };

  useEffect(() => {
    getContactSms();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false })}
        onConfirm={() => deleteContactSm()}
      />
      {addModal()}
      <p className="display-5 text-center">Contact Social Media</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary shadow-none"
          onClick={() => setModal(true)}
        >
          Add Contact Social Media
        </button>
      </div>
      <div className="table-responsive" style={{ paddingBottom: 200 }}>
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">URL</th>
              <th scope="col">sm type</th>
              <th scope="col">contacts</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              return (
                <ContactSmItem
                  key={i}
                  inputs={modalInputs}
                  finishEditing={editContactSm}
                  contactsm={d}
                  selectValues={selectValues}
                  showConfModal={() => {
                    currID.current = d.id;
                    setConfModal({
                      title: `Are you sure you want to delete: ${d.name}`,
                      show: true,
                    });
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactSm;
