import React from "react";
import { Navigate } from "react-router-dom";

function AuthedRoute({ component: Component }) {
  const hasJWT = () => {
    let flag = false;

    //check user has JWT token
    localStorage.getItem("token") ? (flag = true) : (flag = false);
    //console.log(localStorage.getItem("token"));
    return flag;
  };

  if (!hasJWT()) return <Component />;
  else return <Navigate to={"/home"} replace />;
}

export default AuthedRoute;
