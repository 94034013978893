import React from "react";
import { Navigate } from "react-router-dom";
import NavBar from "../pages/NavBar";
import jwtDecode from "jwt-decode";
function ProtectedRoute({ component: Component, superadmin = false }) {
  const isSuperadmin = () => {
    let flag = false;
    const token = localStorage.getItem("token");
    if (token) flag = jwtDecode(token).superuser;
    return flag;
  };
  const hasJWT = () => {
    let flag = false;
    //check user has JWT token
    localStorage.getItem("token") ? (flag = true) : (flag = false);

    return flag;
  };

  if (hasJWT() && !superadmin) {
    return (
      <>
        <NavBar />
        <Component />
      </>
    );
  } else if (superadmin && hasJWT()) {
    if (isSuperadmin()) {
      return (
        <>
          <NavBar />
          <Component />
        </>
      );
    } else return <Navigate to={"/home"} replace />;
  } else return <Navigate to={"/login"} replace />;
}

export default ProtectedRoute;
