import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../components/Editor';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const statusOptions = [
    { value: "Active", label: "Active" },
    { value: "Closed", label: "Closed" },
    { value: "Pending", label: "Pending" },
    { value: "Cancelled", label: "Cancelled" },
  ];

const EditProject = () => {
    const { register, handleSubmit, control, setValue,  resetField, reset} = useForm({
        defaultValues: {start_date: new Date(),end_date: new Date()}});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [project, setProject] = useState(null);
    const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
    const {id} = useParams()
    const navigate = useNavigate();
    const getProject = async () => {
        const {status, data, msg} = await fetchData(`/projects/${id}/editoptions`);
        if(status) {
          if(data) {
            //console.log(data)
            setProject(data)
            setValue("start_date", new Date(data.starting_date));
            setValue("end_date", new Date(data.ending_date));
            const blocksFromHTML = convertFromHTML(data.details ? data.details : '');
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            setEditorState(() => EditorState.createWithContent(state));
          }
        } else setError(msg)
        setLoading(false);
      }

      const editProject = async (datax) =>{
        setLoading(true);
        const dataToSend = {...datax, customer: datax.customer.value, manager: datax.manager.value, 
            project_type: datax.project_type.value,
            status: datax.status.value, starting_date: formatDate(datax.start_date), ending_date: formatDate(datax.end_date),
            details: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")
        }
        if (error) setError(null);
        const { status, data, msg } = await sendData(`/projects/edit/${id}`, dataToSend);
        if (status) {
          showToast("success", "Project updated")
          navigate(`/projects/${id}`);
        } else{ setError(msg);
        setLoading(false);
        }
      }

      useEffect(()=>{
        getProject()
      },[])

    if (loading) return <Loading />;
  else return (
    <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Edit Project</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(editProject)}>
          <label className="form-label">Title</label>
          <input
          defaultValue={project.name}
            {...register("name", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Details</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />

<label className="form-label mt-4">Manager</label>
          <Controller
        name="manager"
        rules={{required: true}}
        control={control}
        defaultValue={{value: project.manager, label: project.managername}}
        render={({ field }) => <Select 
          {...field} 
          options={project.users} 
        />}
      />

<label className="form-label mt-4">Customer</label>
          <Controller
        name="customer"
        rules={{required: true}}
        control={control}
        defaultValue={{value: project.customer, label: project.customername}}
        render={({ field }) => <Select 
          {...field} 
          options={project.customers} 
        />}
      />

        <label className="form-label mt-4">Members</label>
          <Controller
        name="members"
        rules={{required: true}}
        control={control}
        defaultValue={project.members}
        render={({ field }) => <Select 
          {...field} 
          isMulti
          options={project.users} 
        />}
      />

<label className="form-label mt-4">Project Type</label>
          <Controller
        name="project_type"
        rules={{required: true}}
        control={control}
        defaultValue={{value: project.project_type, label: project.project_type_name}}
        render={({ field }) => <Select 
          {...field} 
          options={project.project_types} 
        />}
      />

          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        defaultValue={{value:project.status, label: project.status}}
        render={({ field }) => <Select 
          {...field} 
          options={statusOptions} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: true}}
                
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Edit Project" />
        </form>
    </div>    
  )
}

export default EditProject