import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { toast } from "react-toastify";
import fetchHead from "../../Utils/fetchHead";
import uri from "../../Utils/uri";
import axios from "axios";
import Select from "react-select";

const businessTypeOptions = [
  { value: "Accessories", label: "Accessories" },
  { value: "Architecture", label: "Architecture" },
  { value: "Beauty Institute", label: "Beauty Institute" },
  { value: "Car Agent", label: "Car Agent" },
  { value: "Construction", label: "Construction" },
  { value: "Designer", label: "Designer" },
  { value: "Diet Center", label: "Diet Center" },
  { value: "Education", label: "Education" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Financial", label: "Financial" },
  { value: "Food & Catering", label: "Food & Catering" },
  { value: "Furniture & Home", label: "Furniture & Home" },
  { value: "Government", label: "Government" },
  { value: "Health & Fitness", label: "Health & Fitness" },
  { value: "Hotels & Resorts", label: "Hotels & Resorts" },
  { value: "Industrial", label: "Industrial" },
  { value: "Insurance", label: "Insurance" },
  { value: "Other", label: "Other" },
];

const CreateCustomer = () => {
  const [sourcesData, setSourcesData] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCompany, setIsCompany] = useState(false);
  const [name, setName] = useState("");
  const [financial_number, setFinancial_Number] = useState("");
  const [vat, setVat] = useState(false);
  const [commercial_registeration, setCommercial_Registeration] = useState("");
  const [business_type, setBusiness_Type] = useState("");
  const [error, setError] = useState(null);
  const [phone, setPhone] = useState("");
  const [website, SetWebsite] = useState("");
  const [status, setStatus] = useState("");
  const [source, setSource] = useState("");
  const navigate = useNavigate();

  const dataInvalid =
    !name.trim() ||
    !business_type ||
    !phone.trim() ||
    !status ||
    !source ||
    !email.trim() ||
    !commercial_registeration.trim() ||
    !financial_number;

  const createCustomer = async () => {
    try {
      setLoading(true);
      if (error) setError(null);
      const data = {
        email,
        comapny: +isCompany,
        name,
        financial_number: +financial_number,
        vat: +vat,
        commercial_registeration,
        business_type: business_type ? business_type.value : null,
        phone: phone,
        website,
        status: status ? status.value : null,
        source: source ? +source.value : null,
      };
      const req = await axios.post(uri("/customers/add"), data, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setLoading(false);
        toast.success("Customer created", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/customers");
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured. Please try again!");
      setLoading(false);
    }
  };

  const getSources = async () => {
    try {
      const req = await axios.get(uri("/customers/sources"), fetchHead());
      const res = req.data;

      if (res.status == 200) {
        setSourcesData(res.data);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getSources();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Add Customer</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <label className="form-label">Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(t) => setEmail(t.target.value)}
        />

        <label className="form-label mt-3">Name</label>
        <input
          value={name}
          type="text"
          className="form-control"
          onChange={(t) => setName(t.target.value)}
        />

        <label className="form-label mt-3">Financial Number</label>
        <input
          value={financial_number}
          type="number"
          className="form-control"
          onChange={(t) => setFinancial_Number(t.target.value)}
        />
        <label className="form-label mt-3">Commercial Registeration</label>
        <input
          value={commercial_registeration}
          type="text"
          className="form-control"
          onChange={(t) => setCommercial_Registeration(t.target.value)}
        />

        <label className="form-label mt-3">Phone</label>
        <input
          value={phone}
          type="text"
          className="form-control"
          onChange={(t) => setPhone(t.target.value)}
        />
        <label className="form-label mt-3">Website</label>
        <input
          value={website}
          type="text"
          className="form-control"
          onChange={(t) => SetWebsite(t.target.value)}
        />

        <label className="form-label mt-3">Status</label>
        <Select
          isClearable
          options={[
            { value: "Active", label: "Active" },
            { value: "Suspended", label: "Suspended" },
            { value: "Terminated", label: "Terminated" },
          ]}
          onChange={(v) => setStatus(v)}
        />

        <label className="form-label mt-3">Business Type</label>
        <Select
          isClearable
          options={businessTypeOptions}
          onChange={(v) => setBusiness_Type(v)}
        />

        <label className="form-label mt-3">Source</label>
        <Select
          isClearable
          options={sourcesData}
          onChange={(v) => setSource(v)}
        />

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isCompany}
            id="flexCheckDefault"
            onChange={(e) => setIsCompany(!isCompany)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Comapny
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={vat}
            id="flexCheckDefault"
            onChange={(e) => setVat(!vat)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            VAT
          </label>
        </div>
        <button
          disabled={dataInvalid}
          className="btn btn-success mt-4"
          onClick={() => createCustomer()}
        >
          Create Customer
        </button>
      </div>
    );
};

export default CreateCustomer;
