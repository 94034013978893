import React, { useState } from "react";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { toast } from "react-toastify";
import fetchHead from "../../Utils/fetchHead";
import uri from "../../Utils/uri";
import axios from "axios";
import { sendData } from "../../Utils/utils";
const CreateUser = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [superuser, setSuperuser] = useState(false);
  const [active, setActive] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dataInvalid =
    !username.trim() ||
    !password.trim() ||
    !firstName.trim() ||
    !lastName.trim();

  const createUser = async () => {
    setLoading(true);
    if (error) setError(null);
    const datax = {
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      superuser: +superuser,
      username,
      is_active: +active,
    };
    // const req = await axios.post(uri("/users/register"), data, fetchHead());
    // const res = req.data;
    const { status, data, msg } = await sendData("/users/register", datax);
    if (status) {
      setLoading(false);
      toast.success("User created", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      navigate("/users");
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <p className="display-5 text-center">Add User</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <label className="form-label">
          Email<i className="text-muted">(optional)</i>
        </label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={(t) => setEmail(t.target.value)}
        />

        <label className="form-label mt-3">Username</label>
        <input
          value={username}
          type="text"
          className="form-control"
          onChange={(t) => setUsername(t.target.value)}
        />

        <label className="form-label mt-3">First Name</label>
        <input
          value={firstName}
          type="text"
          className="form-control"
          onChange={(t) => setFirstName(t.target.value)}
        />
        <label className="form-label mt-3">Last Name</label>
        <input
          value={lastName}
          type="text"
          className="form-control"
          onChange={(t) => setLastName(t.target.value)}
        />

        <label className="form-label mt-3">Password</label>
        <input
          value={password}
          type="password"
          className="form-control"
          onChange={(t) => setPassword(t.target.value)}
        />

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={superuser}
            id="flexCheckDefault"
            onChange={(e) => setSuperuser(!superuser)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            superuser
          </label>
        </div>

        <div className="form-check mt-4">
          <input
            className="form-check-input"
            type="checkbox"
            checked={active}
            id="flexCheckDefault"
            onChange={(e) => setActive(!active)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            active
          </label>
        </div>
        <button
          disabled={dataInvalid}
          className="btn btn-success mt-4"
          onClick={() => createUser()}
        >
          Create User
        </button>
      </div>
    );
};

export default CreateUser;
