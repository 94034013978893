import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {toast} from 'react-toastify'

export const showToast = (type, txt, duration=2500) => {
  if(type == 'success')
    toast.success(txt, {
    position: "top-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
  else if(type == 'error')
  toast.error(txt, {
    position: "top-center",
    autoClose: duration,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
}

export const toDateTime = (date) => {
  const offsetDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  return offsetDate.toISOString().slice(0, 19).replace("T", " ");
};

export const genURI = (path) => {
  if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "test")
    return `http://localhost:8000/api${path}`;
  else return `${window.location.origin}/api${path}`;
};

export const splitTime = (start, end) => {
  const dateFuture = new Date(end);
  const dateNow = new Date(start);

  let seconds = Math.floor((dateFuture - dateNow) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours - days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  return { days, hours, minutes, seconds };
};


export const convertMinutesToString = (minutes) => {
  let days = Math.floor(minutes / 1440);
  minutes -= days * 1440;
  let hours = Math.floor(minutes / 60);
  minutes -= hours * 60;

  let timeString = "";
  if (days > 0) {
    timeString += days + " day" + (days > 1 ? "s" : "");
    if (hours > 0 || minutes > 0) {
      timeString += ( minutes <= 0 ? " and ":", ")
    }
  }
  if (hours > 0) {
    timeString += hours + " hour" + (hours > 1 ? "s" : "");
    if (minutes > 0) {
      timeString += " and ";
    }
  }
  if (minutes > 0) {
    timeString += minutes + " minute" + (minutes > 1 ? "s" : "");
  }

  return timeString;
};

/*function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}*/

export const fetchData = async (uri, token = true, del = false) => {
  try {
    const req = await fetch(genURI(uri), {
      method: !del ? "GET" : "DELETE",
      headers: {
        Authorization: token ? localStorage.getItem("token") : "",
      },
    });
    const res = await req.json();
    if (req.status == 200) {
      if (res.status == 200) return { status: true, data: res.data };
      else if (res.status == 400) return { status: false, msg: res.msg };
      else {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } else throw new Error("Server Error");
  } catch (err) {
    return { status: false, msg: "Server error" };
  }
};

export const sendData = async (uri, data, token = true, put = false) => {
  try {
    const req = await fetch(genURI(uri), {
      method: !put ? "POST" : "PUT",
      mode: process.env.NODE_ENV == "production" ? "same-origin" : "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? localStorage.getItem("token") : "",
      },
      body: JSON.stringify(data),
    });
    const res = await req.json();
    if (req.status == 200) {
      if (res.status == 200) return { status: true, data: res.data };
      else if (res.status == 400) return { status: false, msg: res.msg };
      else {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    } else throw new Error("Server Error");
  } catch (err) {
    return { status: false, msg: "Server error" };
  }
};

export const getCurrentUser = (navigate) => {
  if (localStorage.getItem("token")) {
    return jwtDecode(localStorage.getItem("token"));
    //console.log(jwtDecode(localStorage.getItem("token")));
  } else navigate("/login", { replace: true });
};

export const requestNotificationPermission = () => {
  if ("Notification" in window) {
    if (Notification.permission != "granted") {
      Notification.requestPermission().then((permission) => {
        return;
      });
    }
  }
};
export const sendNotification = (tag) => {
  if ("Notification" in window) {
    if (Notification.permission != "granted") {
      Notification.requestPermission().then((permission) => {
        if (permission == "granted") {
          let notification = new Notification("Update status", {
            body: "Please update your status",
            tag,
            icon: require("../assets/osina-logo.jpeg"),
          });
        }
      });
    } else {
      setTimeout(() => {
        let notification = new Notification("Update status", {
          body: "Please update your status",
          icon: require("../assets/osina-logo.jpeg"),
          tag,
        });
      }, 1000);
    }
  } else return false;
};

export const formatDate = date => {
  var d = date.getDate();
  var m = date.getMonth() + 1; //January is 0!
  var y = date.getFullYear();
  var h = date.getHours();
  var i = date.getMinutes();
  var s = date.getSeconds();
  return y + '-' + (m<10 ? '0' : '') + m + '-' + (d<10 ? '0' : '') + d + ' ' + (h<10 ? '0' : '') + h + ':' + (i<10 ? '0' : '') + i + ':' + (s<10 ? '0' : '') + s;
}