import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchData, sendData, showToast, getCurrentUser } from "../../Utils/utils";
import {
  faAngleDown,
  faFingerprint,
  faCircleInfo,
  faHourglass,
  faUserTag,
  faCalendar,
  faUsers,
  faXmark, faCheck, faUser
} from "@fortawesome/free-solid-svg-icons";
import Collapse from "react-bootstrap/Collapse";
import { Badge, Modal, Button } from "react-bootstrap";
import JobItem from "../../components/JobItem";
import MilestoneItem from "../../components/MilestoneItem";
import TaskItem from "../../components/TaskItem";
import ConfirmModal from "../../components/ConfirmModal";

const projectDetailsItems = [
  { icon: faFingerprint, field: "id", title: "Id" },
  { icon: faCircleInfo, field: "name", title: "Name" },
  { icon: faUser, field: "managername", title: "Manager", type: 'badge', urlField: 'manager', url: '/users/' },
  { icon: faHourglass, field: "status", title: "Status" },
  {
    icon: faUserTag,
    field: "customerName",
    title: "Customer",
    type: "badge",
    urlField: "customerID",
    url: `/customers/`,
  },
  { icon: faCircleInfo, field: "details", title: "Details", type: "editor" },
  { icon: faCircleInfo, field: "project_type", title: "Type" },
  { icon: faCalendar, field: "starting_date", title: "Starting date" },
  { icon: faCalendar, field: "ending_date", title: "Ending date" },
];

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [milestoneOpen, setMilestoneOpen] = useState(false);
  const [confModal, setConfModal] = useState({ title: "", show: false, type: null, id:null });
  const [jobOpen, setJobOpen] = useState(false);
  const [taskOpen, setTaskOpen] = useState(false);
  const timeAmount = useRef({id: null, time: 0});
  const [show, setShow] = useState(false);
  const currentUser = getCurrentUser(navigate);

  const addTime = async () =>{
    setLoading(true);
    if (error) setError(null);
    const { status, data, msg } = await sendData(`/tasks/${timeAmount.current.id}/addtime`, {time: timeAmount.current.time});
    if (status) {
      timeAmount.current = {id: null, time: 0}
      showToast("success", "Time added")
      getProject()
      setShow(false);
    } else { setError(msg);
    setLoading(false);
    }
  };

  const renderModal = () => {
    return (
      <Modal
      show={show}
      onHide={()=>{
        setShow(false)
        timeAmount.current.time = 0;
        timeAmount.current.id = null;
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>Add time <span className="fst-italic text-muted" >(in minutes)</span></p>
        <input type={"number"} className='form-control' onChange={(e)=>timeAmount.current.time = +e.target.value}  />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={()=>{
          setShow(false)
          timeAmount.current.time = 0;
          timeAmount.current.id = null;
        }} >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
        <Button variant="success" onClick={()=>addTime()} >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </Modal.Footer>
    </Modal>
    )
  }

  const getProject = async () => {
    const {status, data, msg} = await fetchData(`/projects/${id}`);
    if(status) {
        if(data) setProject(data)
    } else setError(msg)
    setLoading(false);
  }

  const deleteItem = async (type, idx) =>{
    const {status, data, msg} = await fetchData(`/${type}/${idx}`, true, true);
    if(status) {
      setLoading(true);
      setConfModal({ title: "", show: false, type: null, id:null })
      showToast("success", `${type.slice(0,-1)} deleted`)
      if(type != 'projects') getProject(); 
      else navigate("/projects");
    } else{ setError(msg)
    setLoading(false);
    }
  }

  const setShowx = (id) => {
    setShow(true);
    timeAmount.current.id = id;
  }

  useEffect(() => {
    getProject();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container pb-5">
        {renderModal()}
        <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false, type:null, id: null })}
        onConfirm={()=>deleteItem(confModal.type, confModal.id)}
      />
        <h2 className="display-4 text-center">{project.name}</h2>
        <p className="text-muted text-center">{project.id}</p>

        <button
          className="btn btn-primary shadow-none"
          onClick={() => navigate(`/projects/${project.id}/milestones/add`)}
        >
          Add Milestone
        </button>

        <button
          className="btn btn-primary shadow-none ms-3"
          onClick={() => navigate(`/projects/${project.id}/jobs/add`)}
        >
          Add Epic
        </button>

        <button className="btn btn-primary shadow-none ms-3"
        onClick={() => navigate(`/projects/${project.id}/tasks/add`)}>Add Task</button>

<button className="btn btn-outline-primary shadow-none ms-3"
        onClick={() => navigate(`/projects/${project.id}/edit`)}>Edit Project</button>

      {currentUser.superuser &&
        <button className="btn btn-outline-danger shadow-none ms-3"
          onClick={() => setConfModal({show: true, title: "Are you sure you want to delete this project?", type: "projects", id: project.id})}
        >Delete Project</button> }

        <div
          className="border rounded pt-2 px-4 mt-4"
          onClick={() => setDetailsOpen(!detailsOpen)}
        >
          <div className="cursor-pointer d-flex justify-content-between align-items-center">
            <p className="display-6" style={{ fontSize: "30px" }}>
              Project Details
            </p>
            <FontAwesomeIcon
              icon={faAngleDown}
              size={"2x"}
              transform={{ rotate: detailsOpen ? -180 : 0 }}
            />
          </div>
          <Collapse in={detailsOpen}>
            <div>
              <ul className="list-group list-group-flush mt-1">
                {projectDetailsItems.map((item, i) => {
                  return (
                    <li key={i} className="list-group-item">
                      <FontAwesomeIcon icon={item.icon} /> <b>{item.title}:</b>{" "}
                      {item.type == "badge" ? <Badge
                          pill
                          bg="primary"
                          className="cursor-pointer"
                          onClick={() =>
                            navigate(`${item.url}${project[item.urlField]}`)
                          }
                        >
                          {project[item.field]}
                        </Badge> : item.type == 'editor' ? <span className="editor-main-text" dangerouslySetInnerHTML={{ __html: project[item.field] }}></span> : (
                        project[item.field]
                      )}
                    </li>
                  );
                })}

                <li className="list-group-item">
                  <FontAwesomeIcon icon={faUsers} /> <b>Members:</b>{" "}
                  {project.members.map((m, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Badge
                          pill
                          bg="primary"
                          className="cursor-pointer"
                          onClick={() => navigate(`/users/${m.user}`)}
                        >
                          {m.username}
                        </Badge>{" "}
                      </React.Fragment>
                    );
                  })}
                </li>
              </ul>
            </div>
          </Collapse>
        </div>

        {/* //! Milestone part */}
        <div className="border rounded pt-2 px-4 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-between align-items-center"
            onClick={() => setMilestoneOpen(!milestoneOpen)}
          >
            <p className="display-6" style={{ fontSize: "30px" }}>
              Milestones
            </p>
            <FontAwesomeIcon
              icon={faAngleDown}
              size={"2x"}
              transform={{ rotate: milestoneOpen ? -180 : 0 }}
            />
          </div>
          <Collapse in={milestoneOpen}>
            <div>
              {project.milestones.map((milestone, i) => (
                <MilestoneItem projectid={project.id} setConfModal={setConfModal} navigate={navigate} key={Math.random()} milestone={milestone}  />
              ))}
              {project.milestones.length > 1 && (
                <button className="btn btn-dark mb-3" onClick={()=>navigate(`/projects/${project.id}/milestones`)}>See all</button>
              )}
            </div>
          </Collapse>
        </div>

        {/* //! Jobs part */}

        <div className="border rounded pt-2 px-4 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-between align-items-center"
            onClick={() => setJobOpen(!jobOpen)}
          >
            <p className="display-6" style={{ fontSize: "30px" }}>
              Epics
            </p>
            <FontAwesomeIcon
              icon={faAngleDown}
              size={"2x"}
              transform={{ rotate: jobOpen ? -180 : 0 }}
              //className="angledown"
            />
          </div>
          <Collapse in={jobOpen}>
            <div>
              {project.jobs.map((job, i) => (<JobItem showMilestone projectid={project.id} setConfModal={setConfModal} key={Math.random()} job={job} navigate={navigate} />)
              
              )}
              {project.jobs.length > 1 && (
                <button className="btn btn-dark mb-3" onClick={()=>navigate(`/projects/${project.id}/jobs`)}>See all</button>
              )}
            </div>
          </Collapse>
        </div>

        {/* //! Tasks part */}
        <div className="border rounded pt-2 px-4 mt-4">
          <div
            className="cursor-pointer d-flex justify-content-between align-items-center"
            onClick={() => setTaskOpen(!taskOpen)}
          >
            <p className="display-6" style={{ fontSize: "30px" }}>
              Tasks
            </p>
            <FontAwesomeIcon
              icon={faAngleDown}
              size={"2x"}
              transform={{ rotate: taskOpen ? -180 : 0 }}
              //className="angledown"
            />
          </div>
          <Collapse in={taskOpen}>
            <div>
              {project.tasks.map((task, i) => (<TaskItem showJob showMilestone setShowx={setShowx} setConfModal={setConfModal} key={Math.random()} task={task} navigate={navigate} />)
              
              )}
              {project.tasks.length > 1 && (
                <button className="btn btn-dark mb-3" onClick={()=>navigate(`/projects/${project.id}/tasks`)}>See all</button>
              )}
            </div>
          </Collapse>
        </div>

      </div>
    );
};

export default ProjectDetails;
