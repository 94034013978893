import React, { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import axios from "axios";
import ErrorBox from "../components/ErrorBox";
import uri from "../Utils/uri";
import AddModal from "../components/AddModal";
import ProjectTypeItem from "../components/ProjectTypeItem";
import fetchHead from "../Utils/fetchHead";
import ConfirmModal from "../components/ConfirmModal";
import { toast } from "react-toastify";
const ProjectTypes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalInputs, setModalInputs] = useState([
    { title: "Name", type: "text", field: "name", value: "", optional: false },
    {
      title: "Color",
      type: "color",
      field: "color",
      value: "",
      optional: false,
    },
  ]);
  const currID = useRef(0);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const [modalErr, setModalErr] = useState(null);
  const [data, setData] = useState([]);

  const getProjectTypes = async () => {
    try {
      setError(null);
      const URI = uri("/projecttypes");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setData(res.data);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const addProjectType = async (data) => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      let body = {};
      data.forEach((d) => (body[d.field] = d.value));
      const URI = uri("/projecttypes/add");
      const req = await axios.post(URI, body, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        toast.success("Project type added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setModal(false);
        getProjectTypes();
      } else {
        setModalErr(res.data);
        setLoading(false);
      }
    } catch (err) {
      setModalErr("An error has occured!");
      setLoading(false);
    }
  };

  const deleteProjectType = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const req = await axios.delete(
        uri(`/projecttypes/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Project type deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getProjectTypes();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };
  //test
  const editProjectType = async (id, name, color) => {
    try {
      setLoading(true);
      setError(null);
      const req = await axios.put(
        uri(`/projecttypes/${id}`),
        { name, color },
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Project type edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getProjectTypes();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjectTypes();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <Container>
        <ConfirmModal
          title={confModal.title}
          show={confModal.show}
          onHide={() => setConfModal({ title: "", show: false })}
          onConfirm={deleteProjectType}
        />
        <AddModal
          title={"Add Project Type"}
          show={modal}
          size={"sm"}
          onHide={() => setModal(false)}
          inputs={modalInputs}
          onConfirm={addProjectType}
          err={modalErr}
        />
        <p className="display-5 text-center">Project Types</p>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary shadow-none"
            onClick={() => setModal(true)}
          >
            Add Project Type
          </button>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered mt-3 table-striped">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">name</th>
                <th scope="col">color</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <ProjectTypeItem
                    showConfModal={() => {
                      currID.current = item.id;
                      setConfModal({
                        show: true,
                        title: `Are you sure you want to delete project type: ${item.name}`,
                      });
                    }}
                    pType={item}
                    key={i}
                    finishEditing={editProjectType}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Container>
    );
};

export default ProjectTypes;
