import React from "react";
const DailyReportItem = ({ date, reports, i }) => {
  return (
    <div className={`${i == 0 ? "" : "mt-4"}`}>
      <div className="p-2" style={{backgroundColor: '#242D51'}}>
        <p className="display-5 m-0" style={{ fontSize: 23, color: "white" }}>
          {date}
        </p>
      </div>
      {reports.map((r) => {
        return (
          <div key={Math.random()} style={{ backgroundColor: "#f5f5f5" }}>
            <p className="m-0">
              <span style={{ fontWeight: "bold" }}>{r.username}</span>{" "}
              <span
                className="text-muted"
                style={{ fontStyle: "italic", fontSize: 12 }}
              >
                ({r.created_at})
              </span>
            </p>
            <div
              className="border-bottom editor-main-text"
              style={{ backgroundColor: "#f5f5f5", marginBottom: "10px" }}          
              dangerouslySetInnerHTML={{ __html: r.details }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default DailyReportItem;
