import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fetchHead from "../Utils/fetchHead";
import uri from "../Utils/uri";
import Loading from "../components/Loading";
import ErrorBox from "../components/ErrorBox";
import AddModal from "../components/AddModal";
import ConfirmModal from "../components/ConfirmModal";
import { toast } from "react-toastify";
import TagItem from "../components/TagItem";
const Tags = () => {
  const navigate = useNavigate();
  const currID = useRef(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalErr, setModalErr] = useState(null);
  const [data, setData] = useState([]);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const [modalInputs, setModalInputs] = useState([
    { title: "Name", type: "text", field: "name", value: "", optional: false }
  ]);

  const getTags = async () => {
    try {
      setError(null);
      const URI = uri("/tags");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const addTag = async (body) => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      let dataToSend = {};
      body.forEach((d) => (dataToSend[d.field] = d.value));
      const URI = uri("/tags/add");
      const req = await axios.post(URI, dataToSend, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        toast.success("Tag added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setModal(false);
        getTags();
      } else {
        setModalErr(res.data);
        setLoading(false);
      }
    } catch (err) {
      setModalErr("An error has occured!");
      setLoading(false);
    }
  };

  const deleteCountry = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const req = await axios.delete(
        uri(`/tags/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Tag deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getTags();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  const editTag = async (newCountry) => {
    try {
      setLoading(true);
      setError(null);
      const req = await axios.put(
        uri(`/tags/${newCountry.id}`),
        newCountry,
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Tag edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getTags();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false })}
        onConfirm={() => deleteCountry()}
      />
      <AddModal
        show={modal}
        inputs={modalInputs}
        onHide={() => setModal(false)}
        title={"Add Country"}
        size={"sm"}
        onConfirm={addTag}
        err={modalErr}
      />
      <p className="display-5 text-center">Tags</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary shadow-none"
          onClick={() => setModal(true)}
        >
          Add Tag
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              return (
                <TagItem
                  key={i}
                  inputs={modalInputs}
                  finishEditing={editTag}
                  tag={d}
                  showConfModal={() => {
                    currID.current = d.id;
                    setConfModal({
                      title: `Are you sure you want to delete this tag: ${d.name}`,
                      show: true,
                    });
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Tags;
