import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import ErrorBox from "../components/ErrorBox";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData, showToast, sendData } from "../Utils/utils";
import TaskItem from "../components/TaskItem";
import ConfirmModal from "../components/ConfirmModal";

const Tasksx = () => {
  const [error, setError] = useState(null);
  //const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [confModal, setConfModal] = useState({ title: "", show: false, type: null, id:null });

  const getData = async () => {
    if(error) setError(false);
    const {status, data, msg} = await sendData(`/tasks/all`, {type: "Open"});
    if(status) setData(data)
    else setError(msg);
    //console.log(data)
    setLoading(false)
  }

  const deleteItem = async (type, idx) =>{
    const {status, data: datax, msg} = await fetchData(`/${type}/${idx}`, true, true);
    if(status) {
      setLoading(true);
      setConfModal({ title: "", show: false, type: null, id:null })
      showToast("success", `Epic deleted`)
      //navigate(`/projects/${data.project.id}`);
      setLoading(true)
      getData()
    } else{ setError(msg)
    setLoading(false);
    }
  }

  useEffect(()=>{
    getData();
  },[])

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container pb-5">
        <h2 className="display-4 text-center m-0">Open Tasks</h2>
        <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false, type:null, id: null })}
        onConfirm={()=>deleteItem(confModal.type, confModal.id)}
      />
        <div>
              {data.map((task, i) => (
                <TaskItem showMilestone showProject showJob projectid={task.projectid} setConfModal={setConfModal} navigate={navigate} key={Math.random()} task={task}  />
              ))}
            </div>

        </div>
    )
}

export default Tasksx