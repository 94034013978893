import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDroplet } from "@fortawesome/free-solid-svg-icons";
import { CirclePicker } from "react-color";
const ProjectTypeItem = ({ pType, showConfModal, finishEditing }) => {
  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(pType.name);
  const [newColor, setNewColor] = useState(pType.color);
  return (
    <tr>
      <th scope="row">{pType.id}</th>
      <td>
        {!edit ? (
          pType.name
        ) : (
          <input
            value={newName}
            className="form-control shadow-none"
            onChange={(e) => setNewName(e.target.value)}
          />
        )}
      </td>
      <td>
        {!edit ? (
          <FontAwesomeIcon icon={faDroplet} color={pType.color} size={"2x"} />
        ) : (
          <CirclePicker
            color={newColor}
            onChangeComplete={({ hex }) => setNewColor(hex)}
          />
        )}
      </td>
      <td className="d-flex justify-content-center align-align-items-center">
        <button
          disabled={!newName.trim()}
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else finishEditing(pType.id, newName, newColor);
          }}
        >
          {!edit ? "Edit" : "Confirm"}
        </button>
        {edit && (
          <button
            className="btn btn-secondary shadow-none"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setEdit(false);
              setNewName(pType.name);
            }}
          >
            Cancel
          </button>
        )}
      </td>
      <td className="text-center">
        <button className="btn btn-danger" onClick={showConfModal}>
          Delete
        </button>
      </td>
    </tr>
  );
};

export default ProjectTypeItem;
