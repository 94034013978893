import React from "react";
import { convertMinutesToString } from "../Utils/utils";
const JobItem = ({ job, navigate, setConfModal, projectid, showMilestone })  => {
//console.log({job})
  return (
    <div className="table-responsive mt-2">
      <table className="table table-bordered info-tablex">
        <tbody>
          <tr>
            <td style={{ width: "30%" }}>
              <div>
                <p>
                  <b>Total Time:</b> {convertMinutesToString(job.totaltime)}
                </p>
              </div>
            </td>
            <td>
              <div>
                <button className="btn btn-primary" onClick={()=>navigate(`/projects/jobs/edit/${job.id}`)}>Update</button>
                <button className="btn btn-primary mx-2" onClick={()=>navigate(`/projects/${projectid}/tasks/add`, {state: {jobtitle: job.title, jobid: job.id, milestonetitle: job.milestonetitle, milestone: job.milestone}})}>
                  Add Task
                </button>
                <button className="btn btn-danger" onClick={()=>setConfModal({title: 'Are you sure you want to delete this epic?', show: true, type: 'epics', id: job.id})} >Delete</button>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <b>Title</b>
            </td>
            <td>{job.title}</td>
          </tr>

          <tr>
            <td>
              <b>Details</b>
            </td>
            <td className="editor-main-text" dangerouslySetInnerHTML={{ __html: job.description }}></td>
          </tr>

          <tr>
            <td>
              <b>Status</b>
            </td>
            <td>{job.status}</td>
          </tr>

          {showMilestone && (<tr><td><b>Milestone</b></td><td>{job.milestonetitle}</td></tr>)}

          <tr>
            <td>
              <b>Start Date</b>
            </td>
            <td>{job.starting_date}</td>
          </tr>

          <tr>
            <td>
              <b>End Date</b>
            </td>
            <td>{job.ending_date}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default JobItem;
