import React from "react";
import { useNavigate } from "react-router-dom";
import { toDateTime } from "../Utils/utils";
const CustomerItem = ({ customer }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <th scope="row">{customer.id}</th>
      <td>{customer.name}</td>
      <td>{`${!!customer.company}`}</td>
      <td>{customer.phone}</td>
      <td>{customer.email}</td>
      <td>{customer.website}</td>
      <td>{toDateTime(new Date(customer.date_created))}</td>
      <td>
        <button
          className="btn btn-dark"
          onClick={() => {
            navigate(`/customers/${customer.id}`);
          }}
        >
          See more
        </button>
      </td>
    </tr>
  );
};

export default CustomerItem;
