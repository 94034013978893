import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import fetchHeader from "../../Utils/fetchHead";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faUser,
  faEnvelope,
  faIdCard,
  faCalendar,
  faUserClock,
  faUserLock,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../components/ConfirmModal";
import { toDateTime, fetchData, sendData } from "../../Utils/utils";
import uri from "../../Utils/uri";

const UserDetails = () => {
  const { id } = useParams();
  //const location = useLocation();
  //const { state } = location;
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    title: "",
  });
  const [edit, setEdit] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const navigate = useNavigate();
  const dataList = [
    {
      title: "id",
      icon: <FontAwesomeIcon icon={faFingerprint} />,
      data: user ? user.id : "",
    },
    {
      title: "username",
      icon: <FontAwesomeIcon icon={faUser} />,
      data: user ? user.username : "",
      type: "text",
      key: "username",
    },
    {
      title: "email",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      data: user ? user.email : "",
      type: "text",
      key: "email",
    },
    {
      title: "first name",
      icon: <FontAwesomeIcon icon={faIdCard} />,
      data: user ? user.first_name : "",
      type: "text",
      key: "first_name",
    },
    {
      title: "last name",
      icon: <FontAwesomeIcon icon={faIdCard} />,
      data: user ? user.last_name : "",
      type: "text",
      key: "last_name",
    },
    {
      title: "date joined",
      icon: <FontAwesomeIcon icon={faCalendar} />,
      data: user ? user.date_joined : "",
      type: "datetime-local",
      key: "date_joined",
    },
    {
      title: "active",
      icon: <FontAwesomeIcon icon={faCircleCheck} />,
      data: user ? `${!!user.is_active}` : "",
      type: "checkbox",
      key: "is_active",
    },
    {
      title: "superuser",
      icon: <FontAwesomeIcon icon={faUserLock} />,
      data: user ? `${!!user.superuser}` : "",
      type: "checkbox",
      key: "superuser",
    },
    {
      title: "last login",
      icon: <FontAwesomeIcon icon={faUserClock} />,
      data: user ? user.last_login : "",
      type: "datetime-local",
      key: "last_login",
    },
  ];

  const renderDatePicker = (i) => {
    return (
      <DatePicker
        selected={new Date(editedUser[i.key])}
        onChange={(date) => {
          // const offsetDate = new Date(
          //   date.getTime() - date.getTimezoneOffset() * 60000
          // );
          let newUser = { ...editedUser };
          newUser[i.key] = toDateTime(new Date(date));
          setEditedUser(newUser);
        }}
        utcOffset={0}
        showTimeSelect
        dateFormat="Pp"
        showYearDropdown
        scrollableYearDropdown
      />
    );
  };

  const getUser = async () => {
    // try {
    //   const req = await axios.get(uri(`/users/${id}`), fetchHeader());
    //   const res = req.data;
    //   if (res.status == 200) {
    //     setUser(res.data);
    //     setEditedUser(res.data);
    //     setLoading(false);
    //   } else {
    //     setError(res.data);
    //     setLoading(false);
    //   }
    // } catch (err) {
    //   setError("An error has occured. Please try again!");
    //   setLoading(false);
    // }
    const { status, data, msg } = await fetchData(`/users/${id}`);
    if (status) {
      setUser(data);
      setEditedUser(data);
      setLoading(false);
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setLoading(true);
    setModal({ show: false, title: "" });
    //const req = await axios.delete(uri(`/users/${user.id}`), fetchHeader());
    //const res = req.data;
    const { status, data, msg } = await fetchData(
      `/users/${user.id}`,
      true,
      true
    );
    if (status) {
      toast.success("User deleted", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } else {
      toast.error(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const finishEditing = async () => {
    setLoading(true);
    setModal({ show: false, title: "" });
    const { id, ...newUser } = { ...editedUser };
    // const req = await axios.put(
    //   uri(`/users/${user.id}`),
    //   newUser,
    //   fetchHeader()
    // );
    // const res = req.data;
    const { status, data, msg } = await sendData(
      `/users/${user.id}`,
      newUser,
      true,
      true
    );
    if (status) {
      toast.success("User edited", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      navigate(-1);
    } else {
      toast.error(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container">
        <ConfirmModal
          show={modal.show}
          onHide={() => setModal({ title: "", show: false })}
          title={modal.title}
          onConfirm={
            modal.title.includes("delete") ? deleteUser : finishEditing
          }
        />
        <p className="display-5 text-center">Users</p>
        <div className="container">
          <b>Information</b>

          <ul className="list-group list-group-flush mt-1">
            {dataList.map((i, index) => (
              <li className="list-group-item" key={index}>
                {i.icon} <b>{i.title}</b>:{" "}
                {i.type !== "datetime-local"
                  ? i.data
                  : i.data && toDateTime(new Date(i.data))}{" "}
                {edit && i.title != "id" && (
                  <>
                    {i.type == "datetime-local" ? (
                      renderDatePicker(i)
                    ) : i.type == "text" ? (
                      <input
                        onChange={(e) => {
                          let newUser = { ...editedUser };
                          newUser[i.key] = e.target.value;
                          setEditedUser(newUser);
                        }}
                        value={editedUser[i.key] ? editedUser[i.key] : ""}
                        type={i.type}
                        placeholder={i.title}
                      />
                    ) : (
                      <>
                        {`--> ${!!editedUser[i.key]} `}
                        <input
                          className="form-check-input"
                          checked={Boolean(editedUser[i.key])}
                          type={"checkbox"}
                          onChange={() => {
                            let newUser = { ...editedUser };
                            newUser[i.key] = editedUser[i.key] == 0 ? 1 : 0;
                            setEditedUser(newUser);
                          }}
                        />
                      </>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
          <div className="d-flex">
            <button
              className="btn btn-warning mt-3 shadow-none"
              onClick={() => {
                if (edit)
                  setModal({
                    show: true,
                    title: `Are you sure you want to edit ${user.username}`,
                  });
                else setEdit(true);
              }}
            >
              {edit ? "Finish editing" : "Edit"}
            </button>
            <button
              className="btn btn-danger mt-3 mx-2"
              onClick={() =>
                setModal({
                  show: true,
                  title: `Are you sure you want to delete ${user.username}`,
                })
              }
            >
              Delete User
            </button>
          </div>
          {edit && (
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                setEdit(false);
                setEditedUser(user);
              }}
            >
              Cancel edit
            </button>
          )}
        </div>
      </div>
    );
};

export default UserDetails;
