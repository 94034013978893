import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../../components/Editor';
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const AddTask = () => {
  const { register, handleSubmit, formState, control, setValue, watch } = useForm({
    defaultValues: {
        start_date: new Date(),
        end_date: null,
        extra: false,
    }
  });

  const milestoneWatch = watch("milestone")
  console.log(watch("tags"));

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const milestones = useRef([]);
  const [jobs, setJobs] = useState([]);
  const project = useRef();
  const users = useRef([]);
  const tags = useRef([]);
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const {state} = location
  const getInitialOptions = async () => {
    const {status, data, msg} = await fetchData(`/tasks/addoptions/${id}`);
    if(status) {
        milestones.current = data.milestones;
        users.current = data.users;
        tags.current = data.tags;
        project.current = data.project;
    } else setError(msg)
    setLoading(false);
  }
//console.log(editorState.getCurrentContent().getPlainText('\u0001'))
  const addTask = async (datax) => {
    setLoading(true);
    const dataToSend = {...datax, status:datax.status.value , job: datax.job.value, 
      starting_date: formatDate(datax.start_date), ending_date: datax.end_date ? formatDate(datax.end_date):null, 
      user_id: datax.user_id.value, total_time: Number(datax.total_time), extra: Number(datax.extra),
    description: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")
    }
    if (error) setError(null);
    const { status, data, msg } = await sendData("/tasks/add", dataToSend);
    if (status) {
      console.log(data)
      showToast('success', 'Task updated')
      navigate(`/projects/${id}`);
    } else { setError(msg);
    setLoading(false);
    }
  };

  const getJobs = async () =>{
    const {status, data, msg} = await fetchData(`/epics/bymilestone/${milestoneWatch.value}`);
    if(status) {
        setJobs(data);
    } else setError(msg)
    setLoading(false);
  }

  useEffect(()=>{
    getInitialOptions();
  },[])

  useEffect(()=>{
    if(milestoneWatch)
    getJobs()
  },[milestoneWatch])

  if (loading) return <Loading />;
  else
    return (
        <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Add Task</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(addTask)}>
          <label className="form-label">Title</label>
          <input
            {...register("title", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Description</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />
          
          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        render={({ field, value }) => <Select 
          {...field} 
          options={[
            { value: "Open", label: "Open" },
            { value: "Working on", label: "Working on" },
            { value: "Closed", label: "Closed" },
          ]} 
        />}
      />

<label className="form-label mt-4">Project</label>
          <Controller
        name="project"
        rules={{required: true}}
        control={control}
        defaultValue={project.current}
        render={({ field }) => <Select 
          {...field} 
          isDisabled
          options={[
            { value: project.current.value, label: project.current.label },
          ]} 
        />}
      />

        <label className="form-label mt-4">Milestone</label>
          <Controller
        name="milestone"
        rules={{required: true}}
        control={control}
        defaultValue={state ? {label: state.milestonetitle, value: state.milestone} : null}
        render={({ field }) => <Select 
          {...field} 
          options={milestones.current} 
        />}
      />

        <label className="form-label mt-4">Epic</label>
          <Controller
        name="job"
        rules={{required: true}}
        control={control}
        defaultValue={state ? {label: state.jobtitle, value: state.jobid} : null}
        render={({ field }) => <Select 
          {...field} 
          options={jobs} 
        />}
      />

        <div className="form-check mt-4">
          <input
          {...register("extra", {required:false})}
            className="form-check-input"
            type="checkbox"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Extra
          </label>
        </div>

        <label className="form-label mt-4">Total Time <span className="text-muted fst-italic">(in minutes)</span></label>
        <input className="form-control" type={'number'} min={0} step={1} {...register("total_time", {required:false})} />

        <label className="form-label mt-4">Assigned to</label>
          <Controller
        name="user_id"
        rules={{required: true}}
        control={control}
        render={({ field }) => <Select 
          {...field} 
          options={users.current} 
        />}
      />

  <label className="form-label mt-4">Tags</label>
          <Controller
        name="tags"
        rules={{required: false}}
        control={control}
        render={({ field }) => <Select 
          {...field} 
          isMulti
          options={tags.current} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: false}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Add Task" />
        </form>
      </div>
    );
};

export default AddTask;
