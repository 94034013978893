import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { toDateTime } from "../Utils/utils";
const CountryItem = ({ source, showConfModal, finishEditing, selectData }) => {
  const [edit, setEdit] = useState(false);
  const [newSource, setNewSource] = useState(source);

  return (
    <tr>
      <th scope="row">{source.id}</th>
      <td>
        {!edit ? (
          source.name
        ) : (
          <input
            value={newSource.name}
            type={"text"}
            className="form-control shadow-none"
            onChange={(e) =>
              setNewSource({ ...newSource, ["name"]: e.target.value })
            }
          />
        )}
      </td>
      <td>
        {!edit ? (
          source.date && toDateTime(new Date(source.date))
        ) : (
          <DatePicker
            className="form-control"
            selected={new Date(newSource.date)}
            onChange={(date) => {
              let myNewSource = { ...newSource };
              myNewSource.date = toDateTime(new Date(date));
              setNewSource(myNewSource);
            }}
            utcOffset={0}
            showTimeSelect
            dateFormat="Pp"
            showYearDropdown
            scrollableYearDropdown
          />
        )}
      </td>
      <td>
        {!edit ? (
          source.cbName
        ) : (
          <select
            defaultValue={newSource.contactbook ? newSource.cbId : "NULL"}
            onChange={(e) => {
              setNewSource({ ...newSource, cbId: e.target.value });
            }}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="NULL">None</option>
            {newSource.contactbook && (
              <option value={newSource.cbId}>{newSource.cbName}</option>
            )}
            {selectData.map((c, i) => (
              <option key={i} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        )}
      </td>
      <td className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else finishEditing(newSource);
          }}
        >
          {!edit ? "Edit" : "Finish edit"}
        </button>
        <button
          className={`btn btn-${!edit ? "danger" : "secondary"} shadow-none`}
          style={{ marginLeft: "5px" }}
          onClick={() => {
            if (!edit) showConfModal();
            else {
              setEdit(false);
              setNewSource(source);
            }
          }}
        >
          {!edit ? "Delete" : "Cancel"}
        </button>
      </td>
    </tr>
  );
};

export default CountryItem;
