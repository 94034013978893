import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { fetchData } from "../../Utils/utils";
import ActivityItem from "./ActivityItem";

const Activities = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);

  const getUsersActivities = async () => {
    const { status, data: datax, msg } = await fetchData("/connections/all");
    if (status) {
      //setData(datax);
      let mydata = [];
      datax.forEach((x) => {
        mydata.push({ ...x, end_date: null, origDate: x.start_date });
        if (x.end_date) mydata.push({ ...x, origDate: x.end_date });
      });
      mydata.sort(
        (objA, objB) =>
          Number(new Date(objB.origDate)) - Number(new Date(objA.origDate))
      );
      setData(mydata);
    } else console.log(msg);
    setLoading(false);
  };

  useEffect(() => {
    getUsersActivities();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <h1 className="display-5">Recent Activity</h1>

        <div className="my-4">
          {data.map((activity) => (
            <ActivityItem key={Math.random()} activity={activity} />
          ))}
        </div>
      </div>
    );
};

export default Activities;
