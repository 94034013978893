import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      username: null,
      superuser: false,
    },
    connected: false,
  },
  user: {
    username: null,
    superuser: false,
  },
  connected: false,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setConnection: (state, action) => {
      state.connected = action.payload;
    },
  },
});

export const { setUser, setConnection } = authSlice.actions;
export default authSlice.reducer;
