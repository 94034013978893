import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProjectItem from "../../components/ProjectItem";
import axios from "axios";
import Loading from "../../components/Loading";
import uri from "../../Utils/uri";
import fetchHead from "../../Utils/fetchHead";
const Projects = () => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getProjects = async () => {
    try {
      const req = await axios.get(uri("/projects"), fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setProjects(res.data);
      } else {
        setError(res.data);
      }
      setLoading(false);
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  if (loading) return <Loading />;
  return (
    <div className="container">
      <p className="display-5 text-center">Projects</p>
      <div className="d-flex">
        <button
          onClick={() => navigate("/projects/add")}
          className="btn btn-outline-primary shadow-none"
        >
          Add Project
        </button>
      </div>

      <div className="table-responsive" style={{ paddingBottom: "10%" }}>
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">customer</th>
              <th scope="col">status</th>
              <th scope="col">type</th>
              <th scope="col">started on</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((d, i) => {
              return <ProjectItem project={d} key={i} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Projects;
