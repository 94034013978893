import React, { useState } from "react";
import { Modal, Button, InputGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { SketchPicker, CirclePicker } from "react-color";
import ErrorBox from "./ErrorBox";
import { toDateTime } from "../Utils/utils";
function AddModal({
  show,
  onHide,
  title,
  onConfirm,
  inputs,
  err,
  selectData,
  size,
}) {
  const [data, setData] = useState(inputs);
  //(data);
  const dataInvalid = data.some((d) => {
    if (d.optional) return false;
    else {
      if (d.type == "datetime-local") return false;
      else if (!d.value.trim()) return true;
      else return false;
    }
  });
  return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <p>{title}</p>
        {err && <ErrorBox err={err} type="danger" />}
        {data.map((inp, i) => {
          return (
            <React.Fragment key={i}>
              <Form.Label className={`${i == 0 ? "" : "mt-3"}`}>
                {inp.title}
              </Form.Label>
              {inp.type == "color" ? (
                <CirclePicker
                  color={inp.value}
                  onChangeComplete={({ hex }) =>
                    setData(
                      Object.assign([...data], {
                        [i]: {
                          ...data[i],
                          value: hex,
                        },
                      })
                    )
                  }
                />
              ) : inp.type == "text" || inp.type == "number" ? (
                <InputGroup>
                  <input
                    value={inp.value}
                    type={inp.type}
                    className="form-control"
                    onChange={(e) => {
                      setData(
                        Object.assign([...data], {
                          [i]: {
                            ...data[i],
                            value: e.target.value,
                          },
                        })
                      );
                    }}
                  />
                </InputGroup>
              ) : inp.type == "select" ? (
                <select
                  defaultValue={inp.optional ? "NULL" : ""}
                  onChange={(e) => {
                    //(data);
                    setData(
                      Object.assign([...data], {
                        [i]: {
                          ...data[i],
                          value: e.target.value,
                        },
                      })
                    );
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  {inp.optional ? (
                    <option value="NULL">None</option>
                  ) : (
                    <option value="" disabled>
                      Select Contactbook
                    </option>
                  )}
                  {selectData.map((c, i) => (
                    <option key={i} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              ) : (
                <DatePicker
                  className="form-control"
                  selected={new Date(inp.value)}
                  onChange={(date) => {
                    // const offsetDate = new Date(
                    //   date.getTime() - date.getTimezoneOffset() * 60000
                    // );
                    setData(
                      Object.assign([...data], {
                        [i]: {
                          ...data[i],
                          value: toDateTime(new Date(date)),
                        },
                      })
                    );
                    // let newUser = { ...editedUser };
                    // newUser[i.key] = toDateTime(new Date(offsetDate));
                    // setEditedUser(newUser);
                  }}
                  utcOffset={0}
                  showTimeSelect
                  dateFormat="Pp"
                  showYearDropdown
                  scrollableYearDropdown
                />
              )}
            </React.Fragment>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>
          <FontAwesomeIcon icon={faXmark} />
        </Button>
        <Button
          disabled={dataInvalid}
          variant="success"
          onClick={() => {
            onConfirm(data);
          }}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddModal;
