import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../../components/Editor';
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const AddMilestone = () => {
  const { register, handleSubmit, formState, control, setValue } = useForm({
    defaultValues: {
        start_date: new Date(),
        end_date: null
    }
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
  const project = useRef(null);
  const navigate = useNavigate();
  const {id} = useParams();

  const getProject = async () => {
    const {status, data, msg} = await fetchData(`/projects/${id}/option`);
    if(status) {
        if(typeof data == 'object' && data.hasOwnProperty('value'))
        project.current = {value: data.value, label: data.label}
    } else setError(msg)
    setLoading(false);
  }

  const addMilestone = async (datax) => {
    setLoading(true);
    const dataToSend = {...datax, project: datax.project.value, status: datax.status.value, 
      starting_date: formatDate(datax.start_date), ending_date: datax.end_date ? formatDate(datax.end_date):null,
    description: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")
    }
     if (error) setError(null);
    const { status, data, msg } = await sendData("/milestones/add", dataToSend);
    if (status) {
      showToast("success", "Milestone added")
      navigate(`/projects/${id}`);
    } else{ setError(msg);
    setLoading(false);
    }
  };

  useEffect(()=>{
    getProject();
  },[])

  if (loading) return <Loading />;
  else
    return (
        <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Add Milestone</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(addMilestone)}>
          <label className="form-label">Title</label>
          <input
            {...register("title", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Description</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />

          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        render={({ field }) => <Select 
          {...field} 
          options={[
            { value: "In Progress", label: "In Progress" },
            { value: "Closed", label: "Closed" },
          ]} 
        />}
      />

        <label className="form-label mt-4">Project</label>
          <Controller
        name="project"
        rules={{required: true}}
        control={control}
        defaultValue={project.current}
        render={({ field }) => <Select 
          {...field} 
          isDisabled
          options={[
            { value: project.current.value, label: project.current.label },
          ]} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: false}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Add Milestone" />
        </form>
      </div>
    );
};

export default AddMilestone;
