import React, { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import ErrorBox from "../../components/ErrorBox";
import { toast } from "react-toastify";
import fetchHead from "../../Utils/fetchHead";
import uri from "../../Utils/uri";
import Select from "react-select";
import axios from "axios";
const AddContactBook = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [mobile, setMobile] = useState("");
  const [backupMobile, setBackupMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backupEmail, setBackupEmail] = useState("");
  const [countries, setCountries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [socialMedias, setSocialMedias] = useState([]);
  const [country, setCountry] = useState("");
  const [sm, setSm] = useState([]);
  const [groups_, setGroups_] = useState([]);
  const navigate = useNavigate();
  console.log({ groups_, country });

  const dataInvalid = !firstName.trim() || !lastName.trim();

  const inputs = [
    {
      label: "Email",
      value: email,
      setValue: setEmail,
      type: "email",
      optional: true,
    },
    {
      label: "Backup Email",
      value: backupEmail,
      setValue: setBackupEmail,
      type: "email",
      optional: true,
    },
    {
      label: "First Name",
      value: firstName,
      setValue: setFirstName,
      type: "text",
      optional: false,
    },
    {
      label: "Middle Name",
      value: middleName,
      setValue: setMiddleName,
      type: "text",
      optional: true,
    },
    {
      label: "Last Name",
      value: lastName,
      setValue: setLastName,
      type: "text",
      optional: false,
    },
    {
      label: "Mobile",
      value: mobile,
      setValue: setMobile,
      type: "text",
      optional: true,
    },
    {
      label: "Backup Mobile",
      value: backupMobile,
      setValue: setBackupMobile,
      type: "text",
      optional: true,
    },
    {
      label: "Phone",
      value: phone,
      setValue: setPhone,
      type: "text",
      optional: true,
    },
  ];

  const getOptions = async () => {
    try {
      if (error) setError(null);
      const URI = uri("/contactbooks/addoptions");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setCountries(res.data.countries);
        setGroups(res.data.contactgroups);
        setSocialMedias(res.data.social_medias);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const createContactBook = async () => {
    try {
      setLoading(true);
      if (error) setError(null);
      const data = {
        email,
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        phone: +phone,
        mobile: +mobile,
        backup_mobile: +backupMobile,
        backup_email: backupEmail,
        country: country.value ? +country.value : null,
        groups: groups_,
        sm,
      };
      const req = await axios.post(uri("/contactbooks/add"), data, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setLoading(false);
        toast.success("contact book created", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured. Please try again!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <p className="display-5 text-center">Add Contact Book</p>
        {error && <ErrorBox type={"warning"} err={error} />}

        {inputs.map((i, ind) => {
          return (
            <React.Fragment key={ind}>
              <label className={`form-label ${ind == 0 ? "" : "mt-3"}`}>
                {i.label}
                {i.optional && <i className="text-muted">(optional)</i>}
              </label>
              <input
                type={i.type}
                className="form-control shadow-none"
                value={i.value}
                onChange={(t) => i.setValue(t.target.value)}
              />
            </React.Fragment>
          );
        })}

        <label className="form-label mt-3">
          Country<i className="text-muted">(optional)</i>
        </label>
        <Select
          isClearable
          options={countries}
          onChange={(values) => {
            if (values) setCountry(values);
            else setCountry({ label: "", value: null });
          }}
        />

        <label className="form-label mt-3">
          Group<i className="text-muted">(optional)</i>
        </label>
        <Select
          isClearable
          isMulti
          options={groups}
          onChange={(values) => setGroups_(values)}
        />

        <label className="form-label mt-3">
          Social Media<i className="text-muted">(optional)</i>
        </label>
        <Select
          isClearable
          isMulti
          options={socialMedias}
          onChange={(value) => setSm(value)}
        />

        <button
          disabled={dataInvalid}
          className="btn btn-success mt-4"
          style={{ marginBottom: "100px" }}
          onClick={() => createContactBook()}
        >
          Add
        </button>
      </div>
    );
};

export default AddContactBook;
