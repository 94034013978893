import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import fetchHeader from "../../Utils/fetchHead";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faUser,
  faEnvelope,
  faIdCard,
  faCalendar,
  faGlobe,
  faBriefcase,
  faPhone,
  faCircleInfo,
  faMoneyCheck,
  faBuilding,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../components/ConfirmModal";
import { toDateTime } from "../../Utils/utils";
import uri from "../../Utils/uri";
const UserDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [sourceData, setSourceData] = useState([]);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    title: "",
  });
  const [edit, setEdit] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState(null);
  const navigate = useNavigate();
  const dataList = [
    {
      title: "id",
      icon: <FontAwesomeIcon icon={faFingerprint} />,
      data: customer ? customer.id : "",
    },
    {
      title: "name",
      icon: <FontAwesomeIcon icon={faUser} />,
      data: customer ? customer.name : "",
      type: "text",
      key: "name",
    },
    {
      title: "email",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      data: customer ? customer.email : "",
      type: "text",
      key: "email",
    },
    {
      title: "website",
      icon: <FontAwesomeIcon icon={faGlobe} />,
      data: customer ? customer.website : "",
      type: "text",
      key: "website",
    },
    {
      title: "phone",
      icon: <FontAwesomeIcon icon={faPhone} />,
      data: customer ? customer.phone : "",
      type: "text",
      key: "phone",
    },
    {
      title: "status",
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
      data: customer ? customer.status : "",
      type: "select",
      key: "status",
      choices: [
        { value: "Active", title: "Active" },
        { value: "Suspended", title: "Suspended" },
        { value: "Terminated", title: "Terminated" },
      ],
    },
    {
      title: "financial number",
      icon: <FontAwesomeIcon icon={faMoneyCheck} />,
      data: customer ? customer.financial_number : "",
      type: "number",
      key: "financial_number",
    },
    {
      title: "commercial registeration",
      icon: <FontAwesomeIcon icon={faIdCard} />,
      data: customer ? customer.commercial_registeration : "",
      type: "text",
      key: "commercial_registeration",
    },
    {
      title: "business type",
      icon: <FontAwesomeIcon icon={faBriefcase} />,
      data: customer ? customer.business_type : "",
      type: "select",
      key: "business_type",
      choices: [
        { value: "L", title: "L" },
        { value: "K", title: "K" },
        { value: "G", title: "G" },
      ],
    },
    {
      title: "company",
      icon: <FontAwesomeIcon icon={faBuilding} />,
      data: customer ? `${!!customer.company}` : "",
      type: "checkbox",
      key: "company",
    },
    {
      title: "VAT",
      icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
      data: customer ? `${!!customer.vat}` : "",
      type: "checkbox",
      key: "vat",
    },
    {
      title: "Source",
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
      data: customer ? customer.sourceName : "",
      type: "select",
      key: "source",
      choices: sourceData.map((i) => ({ value: i.id, title: i.name })),
    },
    {
      title: "date created",
      icon: <FontAwesomeIcon icon={faCalendar} />,
      data: customer ? customer.date_created : "",
      type: "datetime-local",
      key: "date_created",
    },
  ];
  //
  const getCustomer = async () => {
    try {
      const req = await axios.get(uri(`/customers/${id}`), fetchHeader());
      const res = req.data;
      if (res.status == 200) {
        setCustomer(res.data.customer);
        setSourceData(res.data.sources);
        setEditedCustomer(res.data.customer);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured. Please try again!");
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      setModal({ show: false, title: "" });
      const req = await axios.delete(
        uri(`/customers/${customer.id}`),
        fetchHeader()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Customer deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      } else {
        toast.error(res.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  const finishEditing = async () => {
    try {
      setLoading(true);
      setModal({ show: false, title: "" });
      const { id, sourceId, sourceName, ...newUser } = { ...editedCustomer };
      console.log(newUser);
      const req = await axios.put(
        uri(`/customers/${customer.id}`),
        newUser,
        fetchHeader()
      );
      const res = req.data;

      if (res.status == 200) {
        toast.success("Customer edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      } else {
        toast.error(res.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container">
        <ConfirmModal
          show={modal.show}
          onHide={() => setModal({ title: "", show: false })}
          title={modal.title}
          onConfirm={
            modal.title.includes("delete") ? deleteUser : finishEditing
          }
        />
        <p className="display-5 text-center">Customer</p>
        <div className="container">
          <b>Information</b>

          <ul className="list-group list-group-flush mt-1">
            {dataList.map((i, index) => (
              <li className="list-group-item" key={index}>
                {i.icon} <b>{i.title}</b>:{" "}
                {i.type !== "datetime-local"
                  ? i.data
                  : i.data && new Date(i.data).toString()}{" "}
                {edit && i.title != "id" && (
                  <>
                    {i.type == "datetime-local" ? (
                      <DatePicker
                        selected={new Date(editedCustomer[i.key])}
                        //value={new Date(i.data)}
                        onChange={(date) => {
                          let newUser = { ...editedCustomer };
                          newUser[i.key] = toDateTime(new Date(date));
                          setEditedCustomer(newUser);
                        }}
                        utcOffset={0}
                        showTimeSelect
                        dateFormat="Pp"
                        showYearDropdown
                        scrollableYearDropdown
                        className="form-control"
                      />
                    ) : i.type == "text" || i.type == "number" ? (
                      <input
                        onChange={(e) => {
                          let newUser = { ...editedCustomer };
                          newUser[i.key] = e.target.value;
                          setEditedCustomer(newUser);
                        }}
                        value={
                          editedCustomer[i.key] ? editedCustomer[i.key] : ""
                        }
                        className="form-control"
                        type={i.type}
                        placeholder={i.title}
                      />
                    ) : i.type == "checkbox" ? (
                      <>
                        {`--> ${!!editedCustomer[i.key]} `}
                        <input
                          className="form-check-input"
                          checked={Boolean(editedCustomer[i.key])}
                          type={"checkbox"}
                          onChange={() => {
                            let newUser = { ...editedCustomer };
                            newUser[i.key] = editedCustomer[i.key] == 0 ? 1 : 0;
                            setEditedCustomer(newUser);
                          }}
                        />
                      </>
                    ) : (
                      <select
                        name="source"
                        className="form-select"
                        defaultValue={customer[i.key]}
                        onChange={(t) => {
                          let newUser = { ...editedCustomer };
                          newUser[i.key] = t.target.value;
                          setEditedCustomer(newUser);
                        }}
                      >
                        <option value="" disabled>
                          Select {i.title}
                        </option>
                        {i.choices.map((op, ind) => (
                          <option key={ind} value={op.value}>
                            {op.title}
                          </option>
                        ))}
                      </select>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
          <div className="d-flex">
            <button
              className="btn btn-warning mt-3 shadow-none"
              onClick={() => {
                if (edit)
                  setModal({
                    show: true,
                    title: `Are you sure you want to edit ${customer.name}`,
                  });
                else setEdit(true);
              }}
            >
              {edit ? "Finish editing" : "Edit"}
            </button>
            <button
              className="btn btn-danger mt-3 mx-2"
              onClick={() =>
                setModal({
                  show: true,
                  title: `Are you sure you want to delete ${customer.name}`,
                })
              }
            >
              Delete Customer
            </button>
          </div>
          {edit && (
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                setEdit(false);
                setEditedCustomer(customer);
              }}
            >
              Cancel edit
            </button>
          )}
        </div>
      </div>
    );
};

export default UserDetails;
