import { useState } from "react";
//import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../state/authReducer";
import ErrorBox from "../components/ErrorBox";
import Loading from "../components/Loading";
import jwtDecode from "jwt-decode";
//import uri from "../Utils/uri";
import { sendData } from "../Utils/utils";
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const error = useSelector((state) => state.auth.error);
  //const errorIsMajor = error == "An error has occured. Please try again";
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const login = async () => {
    setLoading(true);
    if (error) setError(null);
    if (!username.trim() || !password.trim()) return setError("Missing fields");
    // const URL = uri("/login");
    // const req = await axios.post(URL, { username, password });
    // const res = req.data;
    // if (res.status == 200) {
    //   localStorage.setItem("token", res.data);
    //   dispatch(setUser(jwtDecode(res.data)));
    //   dispatch(setAuth(true));
    //   dispatch(setLoading(false));
    //   return navigate("/home");
    // } else {
    //   dispatch(setError(res.data));
    //   dispatch(setLoading(false));
    // }
    const { status, data, msg } = await sendData(
      "/login",
      { username, password },
      false
    );
    if (status) {
      localStorage.setItem("token", data);
      dispatch(setUser(jwtDecode(data)));
      navigate("/home", { replace: true });
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  return (
    <div className="container">
      <h2 className="display-4 text-center">OSINA</h2>
      <div className="border p-5 mt-5">
        {error && <ErrorBox type={"warning"} err={error} />}

        <div className="mb-3">
          <label className="form-label">Username</label>
          <input
            type="text"
            className="form-control"
            onChange={(t) => setUsername(t.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <input
            type="password"
            className="form-control"
            onChange={(t) => setPassword(t.target.value)}
          />
        </div>
      </div>

      <div className="text-center mt-5">
        <button
          disabled={!username.trim() || !password.trim()}
          type="button"
          className="btn btn-primary"
          onClick={() => login()}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
