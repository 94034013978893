import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
const ContactGroupItem = ({
  data,
  i,
  editContactGroup,
  setConfModal,
  selectValues,
  currID,
}) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const [newData, setNewData] = useState(data);
  const defaultSelects = data.contacts.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const [newContacts, setNewContacts] = useState(defaultSelects);
  return (
    <tr key={i}>
      <th scope="row">{newData.id}</th>
      <td>
        {!edit ? (
          newData.name
        ) : (
          <input
            value={newData.name}
            type={"text"}
            className="form-control shadow-none"
            onChange={(e) => setNewData({ ...newData, name: e.target.value })}
          />
        )}
      </td>
      <td>
        {!edit ? (
          newData.contacts.map((c, i) => {
            return (
              <React.Fragment key={i}>
                <Badge
                  pill
                  bg="primary"
                  className="cursor-pointer"
                  onClick={() => navigate(`/contactbooks/${c.id}`)}
                >
                  {c.name}
                </Badge>{" "}
              </React.Fragment>
            );
          })
        ) : (
          <Select
            defaultValue={newContacts}
            options={selectValues}
            isMulti
            onChange={(values) => {
              setNewContacts(values);
            }}
          />
        )}
      </td>
      <td className="d-flex align-items-center justify-content-center h-100">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else editContactGroup(newData.name, newContacts, newData.id, data);
          }}
        >
          {!edit ? "Edit" : "Finish edit"}
        </button>
        <button
          className={`btn btn-danger shadow-none`}
          style={{ marginLeft: "5px" }}
          onClick={() => {
            if (!edit) {
              currID.current = newData.id;
              setConfModal({
                show: true,
                title: `Are you sure you want to delete contact group: ${newData.name}`,
              });
            } else setEdit(false);
          }}
        >
          {!edit ? "Delete" : "Cancel"}
        </button>
      </td>
    </tr>
  );
};

export default ContactGroupItem;
