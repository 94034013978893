import React from "react";

const SmallCircle = ({ color, animate }) => (
  <div
    style={{
      backgroundColor: `${color}`,
      minWidth: 15,
      height: 15,
      borderRadius: "50%",
      position: "relative",
      top: 5,
    }}
  ></div>
);

const ActivityItem = ({ activity }) => {
  const connected = !activity.end_date;
  let msg = `connected at ${activity.start_date}`;
  let minDiff = 0;
  if (!connected) {
    const diff = Math.abs(
      new Date(activity.end_date) - new Date(activity.start_date)
    );
    minDiff = Math.floor(diff / 1000 / 60);
  }
  if (!connected)
    msg = `disconnected at ${activity.end_date} by ${activity.disconnect_type}, session duration: ${minDiff} mins`;
  return (
    <>
      <div
        style={{ backgroundColor: "#f5f5f5" }}
        className="border-bottom d-flex py-1"
      >
        <SmallCircle color={connected ? "green" : "red"} />
        <p className="m-0 ms-1">
          <span className="fw-bold">{activity.username}:</span>{" "}
          <span>{msg}</span>
        </p>
      </div>
    </>
  );
};

export default ActivityItem;
