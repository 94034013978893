import React from "react";
import { convertMinutesToString } from "../Utils/utils";
const MilestoneItem = ({ milestone, navigate, setConfModal, projectid }) => {
//console.log({milestone})
  return (
    <div className="table-responsive mt-2">
      <table className="table table-bordered info-tablex">
        <tbody>
          <tr>
            <td style={{ width: "30%" }}>
              <div>
                <p>
                  <b>Total Time:</b> {convertMinutesToString(milestone.totaltime)}
                </p>

                  <p>
                    <b>Total Extra Time:</b> {convertMinutesToString(milestone.totalextratime)}
                  </p>
                
              </div>
            </td>
            <td>
              <div>
                <button className="btn btn-primary" onClick={()=>navigate(`/projects/milestones/edit/${milestone.id}`)}>Update</button>
                <button className="btn btn-primary mx-2" onClick={()=>navigate(`/projects/${projectid}/jobs/add`, {state: {milestonename: milestone.title, milestoneid: milestone.id}})}>
                  Add Epic
                </button>
                <button className="btn btn-danger" onClick={()=>setConfModal({title: 'Are you sure you want to delete this milestone?', show: true, type: 'milestones', id: milestone.id})}>Delete</button>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <b>Title</b>
            </td>
            <td>{milestone.title}</td>
          </tr>

          <tr>
            <td>
              <b>Details</b>
            </td>
            <td className="editor-main-text" dangerouslySetInnerHTML={{ __html: milestone.description }}></td>
          </tr>

          <tr>
            <td>
              <b>Status</b>
            </td>
            <td>{milestone.status}</td>
          </tr>

          <tr>
            <td>
              <b>Start Date</b>
            </td>
            <td>{milestone.starting_date}</td>
          </tr>

          <tr>
            <td>
              <b>End Date</b>
            </td>
            <td>{milestone.ending_date}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MilestoneItem;
