import React, { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useParams, useNavigate } from "react-router-dom";
import { fetchData, showToast } from "../../../Utils/utils";
import MilestoneItem from "../../../components/MilestoneItem";
import ConfirmModal from "../../../components/ConfirmModal";

const Milestones = () => {
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [confModal, setConfModal] = useState({ title: "", show: false, type: null, id:null });

  const getMilestones = async () => {
    if(error) setError(false);
    const {status, data, msg} = await fetchData(`/milestones/project/${id}`);
    if(status) setData(data)
    else setError(msg);
    console.log(data.project.id)
    setLoading(false)
  }

  const deleteItem = async (type, idx) =>{
    const {status, data: datax, msg} = await fetchData(`/${type}/${idx}`, true, true);
    if(status) {
      setLoading(true);
      setConfModal({ title: "", show: false, type: null, id:null })
      showToast("success", `Milestone deleted`)
      navigate(`/projects/${data.project.id}`);
    } else{ setError(msg)
    setLoading(false);
    }
  }

  useEffect(()=>{
    getMilestones();
  },[])

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container pb-5">
        <h2 className="display-4 text-center m-0">Milestones</h2>
        <p className="text-muted text-center m-0">project: {data.project.name}</p>
        <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false, type:null, id: null })}
        onConfirm={()=>deleteItem(confModal.type, confModal.id)}
      />
        <div>
              {data.milestones.map((milestone, i) => (
                <MilestoneItem projectid={data.project.id} setConfModal={setConfModal} navigate={navigate} key={Math.random()} milestone={milestone}  />
              ))}
            </div>

        </div>
    )
}

export default Milestones