import React from "react";

const ErrorBox = ({ err, type }) => {
  return (
    <div className={`alert alert-${type}`} role="alert">
      {err}
    </div>
  );
};

export default ErrorBox;
