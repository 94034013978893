import React, { useState } from "react";

const CountryItem = ({ country, showConfModal, finishEditing, inputs }) => {
  const [edit, setEdit] = useState(false);
  const [newCountry, setNewCountry] = useState(country);
  return (
    <tr>
      <th scope="row">{country.id}</th>
      {inputs.map((c, i) => {
        if (!edit) return <td key={i}>{country[c.field]}</td>;
        else
          return (
            <td key={i}>
              <input
                value={newCountry[c.field]}
                type={c.type}
                className="form-control shadow-none"
                onChange={(e) =>
                  setNewCountry({ ...newCountry, [c.field]: e.target.value })
                }
              />
            </td>
          );
      })}
      <td className="d-flex align-items-center justify-content-center">
        <button
          className="btn btn-dark shadow-none"
          onClick={() => {
            if (!edit) setEdit(true);
            else finishEditing(newCountry);
          }}
        >
          {!edit ? "Edit" : "Finish edit"}
        </button>
        <button
          className={`btn btn-${!edit ? "danger" : "secondary"} shadow-none`}
          style={{ marginLeft: "5px" }}
          onClick={() => {
            if (!edit) showConfModal();
            else {
              setEdit(false);
              setNewCountry(country);
            }
          }}
        >
          {!edit ? "Delete" : "Cancel"}
        </button>
      </td>
    </tr>
  );
};

export default CountryItem;
