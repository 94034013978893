import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../../components/Editor';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const EditMilestone = () => {
  const project = useRef(null);
  const { register, handleSubmit, control, setValue,  resetField, reset} = useForm({
    defaultValues: {
        start_date: new Date(),
        end_date: null,
    }
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [milestone, setMilestone] = useState(null);
  const navigate = useNavigate();
  const {id} = useParams();
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());

  const getMilestone = async () => {
    const {status, data, msg} = await fetchData(`/milestones/${id}`);
    if(status) {
      if(data) {
        console.log(data)
        project.current = {label: data.projectname, value: data.projectid}
        const blocksFromHTML = convertFromHTML(data.description);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(() => EditorState.createWithContent(state));
        setMilestone(data)
        setValue("start_date", new Date(data.starting_date));
        if(data.ending_date)
        setValue("end_date", new Date(data.ending_date));
      }
    } else setError(msg)
    setLoading(false);
  }

  const editMilestone = async (datax) => {
    setLoading(true);
    const dataToSend = {...datax, project: datax.project.value, status: datax.status.value, 
      starting_date: formatDate(datax.start_date), ending_date: datax.end_date ? formatDate(datax.end_date):null,
    description: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")}
     if (error) setError(null);
    const { status, data, msg } = await sendData(`/milestones/edit/${milestone.id}`, dataToSend);
    if (status) {
      showToast("success", "Milestone updated")
      navigate(`/projects/${milestone.projectid}`);
    } else{ setError(msg);
    setLoading(false);
    }
  };

  useEffect(()=>{
    getMilestone();
  },[])

  if (loading) return <Loading />;
  else return (
        <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Edit Milestone</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(editMilestone)}>
          <label className="form-label">Title</label>
          <input
          defaultValue={milestone.title}
            {...register("title", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Description</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />


          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        defaultValue={{value:milestone.status, label: milestone.status}}
        render={({ field }) => <Select 
          {...field} 
          options={[
            { value: "In Progress", label: "In Progress" },
            { value: "Closed", label: "Closed" },
          ]} 
        />}
      />

        <label className="form-label mt-4">Project</label>
          <Controller
        name="project"
        rules={{required: true}}
        control={control}
        defaultValue={project.current}
        render={({ field }) => <Select 
          {...field} 
          isDisabled
          options={[
            { value: project.current.value, label: project.current.label },
          ]} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: false}}
                
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Edit Milestone" />
        </form>
      </div>
    );
};

export default EditMilestone;
