import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../../components/Editor';
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const EditTask = () => {
  const { register, handleSubmit, formState, control, setValue, watch } = useForm({
    defaultValues: {
        start_date: new Date(),
        end_date: null,
        extra: false,
    }
  });

  const milestoneWatch = watch("milestone")
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const milestone = useRef();
  const job = useRef();
  const user = useRef();
  const navigate = useNavigate();
  const {id} = useParams();
  const [task, setTask] = useState(null);
  const tags = useRef([])
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());

  const getTask = async () => {
    const {status, data, msg} = await fetchData(`/tasks/${id}`);
    if(status) {
      if(data) {
        milestone.current = {label: data.milestonetitle, value: data.milestoneid}
        job.current = {label: data.jobtitle, value: data.jobid}
        user.current = {label: data.username, value: data.userid}
        tags.current = data.tags_options
        setValue("start_date", new Date(data.starting_date));
        if(data.ending_date)
        setValue("end_date", new Date(data.ending_date));
        const blocksFromHTML = convertFromHTML(data.description);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(() => EditorState.createWithContent(state));
        setTask(data)
      }
    } else setError(msg)
    setLoading(false);
  }

  const editTask = async (datax) => {
    setLoading(true);
    const dataToSend = {...datax, status:datax.status.value , job: datax.job.value, 
      starting_date: formatDate(datax.start_date), ending_date: datax.end_date ?formatDate(datax.end_date):null, 
      user_id: datax.user_id.value, total_time: Number(datax.total_time), extra: Number(datax.extra), 
      milestone:null, description: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")}
    if (error) setError(null);
    const { status, data, msg } = await sendData(`/tasks/edit/${task.id}`, dataToSend);
    if (status) {
      showToast('success', 'Task updated')
      navigate(`/projects/${task.project}`);
    } else { setError(msg);
    setLoading(false);
    }
  };



  useEffect(()=>{
    getTask()
  },[])


  if (loading) return <Loading />;
  else
    return (
        <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Edit Task</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(editTask)}>
          <label className="form-label">Title</label>
          <input
           defaultValue={task.title}
            {...register("title", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Description</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />

          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        defaultValue={{label: task.status,value: task.status}}
        render={({ field }) => <Select 
          {...field} 
          options={[
            { value: "Open", label: "Open" },
            { value: "Working on", label: "Working on" },
            { value: "Closed", label: "Closed" },
          ]} 
        />}
      />


        <label className="form-label mt-4">Milestone</label>
          <Controller
        name="milestone"
        rules={{required: true}}
        control={control}
        defaultValue={milestone.current}
        render={({ field }) => <Select 
        isDisabled={true}
          {...field} 
          options={[milestone.current]} 
        />}
      />

        <label className="form-label mt-4">Epic</label>
          <Controller
        name="job"
        rules={{required: true}}
        control={control}
        defaultValue={job.current}
        render={({ field }) => <Select 
          {...field} 
          isDisabled={true}
          options={[job.current]} 
        />}
      />

        <div className="form-check mt-4">
          <input
          {...register("extra", {required:false})}
            className="form-check-input"
            type="checkbox"
            defaultChecked={Boolean(task.extra)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Extra
          </label>
        </div>

        <label className="form-label mt-4">Total Time</label>
        <input className="form-control" defaultValue={task.total_time} type={'number'} min={0} step={1} {...register("total_time", {required:false})} />

        

        <label className="form-label mt-4">Assigned to</label>
          <Controller
        name="user_id"
        rules={{required: true}}
        control={control}
        defaultValue={user.current}
        render={({ field }) => <Select 
          {...field} 
          options={task.users} 
        />}
      />

      <label className="form-label mt-4">Tags</label>
          <Controller
        name="tags"
        rules={{required: false}}
        control={control}
        defaultValue={task.tags}
        render={({ field }) => <Select 
          {...field} 
          isMulti
          options={tags.current} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: false}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Edit Task" />
        </form>
      </div>
    );
};

export default EditTask;
