import React, { useState, useEffect } from "react";
import Editor from "../../components/Editor";
import { useNavigate } from "react-router-dom";
import { getCurrentUser, fetchData, sendData } from "../../Utils/utils";
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { convertFromHTML } from "draft-js";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";

const AddDailyReport = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const currentUser = getCurrentUser(navigate);
  const getDailyReportDraft = async () => {
    const { status, data, msg } = await fetchData(
      `/dailyreports/getdraft/${currentUser.id}`
    );
    if (status) {
      if (data.length != 0) {
        let allHTML = "";
        data.forEach((d) => {
          allHTML += d.details;
        });
        const blocksFromHTML = convertFromHTML(allHTML);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(() => EditorState.createWithContent(state));
      }
    }
    setLoading(false);
  };

  const addDailyReport = async () => {
    setLoading(true);
    const { status, data, msg } = await sendData("/dailyreports/add", {
      details: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''"),
      userid: currentUser.id,
    });
    if (status) {
      toast.success("Daily report added", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      navigate("/dailyreports");
    }
    //setLoading(false);
  };

  useEffect(() => {
    getDailyReportDraft();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <h1 className="display-5 mt-2">Generate Daily Report</h1>
        <Editor editorState={editorState} setEditorState={setEditorState} />
        <button
          disabled={
            stateToHTML(editorState.getCurrentContent()) == "<p><br></p>"
          }
          className="btn btn-primary"
          onClick={() => addDailyReport()}
        >
          Add daily report
        </button>
      </div>
    );
};

export default AddDailyReport;
