import React, { useState, useEffect, useRef } from "react";
import Editor from "../../components/Editor";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { fetchData, getCurrentUser, sendData } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import Select from "react-select";


const AddStatus = () => {
  const navigate = useNavigate();
  const connected = useSelector((state) => state.auth.connected);
  const currentUser = getCurrentUser(navigate);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [checked, setChecked] = useState(false);
  const [selectOptions, setSelectOptions] = useState({options: [], isLoading: true});
  const [task, setTask] = useState(null);
  //console.log(stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''"))

  const getUserTasks = async () => {
    if(error) setError(null);
    const {status, data, msg} = await fetchData(`/tasks/byuser/${currentUser.id}`);
    if(status) setSelectOptions({isLoading: false, options: data.map((i)=>({label:`Task: ${i.title} - Job: ${i.jobtitle} - Project: ${i.name}`, value: i.id}))})
    else {
      setError(msg);
      setSelectOptions({...selectOptions, isLoading: false})
    }
  }

  const updateStatus = async () => {
    setLoading(true);
    if (error) setError(null);
    let dataToSend = { details: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''"),
      userid: currentUser.id}
    if(checked) dataToSend['task'] = task.value;
    //return console.log(dataToSend)
    const { status, data, msg } = await sendData("/status/add", dataToSend);
    if (status) {
      setEditorState(() => EditorState.createEmpty());
      toast.success("Status added", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      navigate("/home");
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <h1 className="display-5">Add Status</h1>
        {error && <ErrorBox err={error} type="danger" />}
        <Editor editorState={editorState} setEditorState={setEditorState} />
        <input type={'checkbox'} checked={checked} onChange={e=>{
          setChecked(e.target.checked)
          if(e.target.checked) {
            setSelectOptions({isLoading:true, options: []})
            getUserTasks()
          }
        }} /><label className="form-label ms-1">Add status to task</label>
        {checked && (
          <div>
          <label className="form-label">Choose task</label>
          <Select value={task} onChange={(t)=>setTask(t)} isLoading={selectOptions.isLoading} loadingMessage={()=>'Loading your tasks...'} options={selectOptions.options} />
          </div>
        )}
        <br />
        <button
          className="btn btn-primary mt-1"
          disabled={
            stateToHTML(editorState.getCurrentContent()) == "<p><br></p>" || (checked && !task)
          }
          onClick={() =>
            connected
              ? updateStatus()
              : toast.error("Not connected", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                })
          }
        >
          Add Status
        </button>
      </div>
    );
};

export default AddStatus;
