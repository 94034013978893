import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import taskLogo from "../assets/clipboard-list-solid.png";
import projectLogo from "../assets/project-diagram-solid.png";
import usersLogo from "../assets/users-solid.png";
import userTag from "../assets/user-tag-solid.png";
import HomeCard from "../components/HomeCard";
import fetchHead from "../Utils/fetchHead";
import axios from "axios";
import Loading from "../components/Loading";
import uri from "../Utils/uri";
import Avatar from "react-avatar";
import {
  fetchData,
  getCurrentUser,
  sendData,
  sendNotification,
  showToast,
} from "../Utils/utils";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import { useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faThumbsUp, faPaperPlane, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const navigate = useNavigate();
  const [loading, setLoadingState] = useState(true);
  const currentUser = getCurrentUser(navigate);
  //console.log({currentUser})
  const connected = useSelector((state) => state.auth.connected);
  const [lastStatus, setLastStatus] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [statusLikes, setStatusLikes] = useState([]);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [data, setData] = useState({
    users: 0,
    projects: 0,
    //tasks: 0,
    customers: 0,
    opentasks: 0,
  });
  const [likeLoading, setLikeLoading] = useState(false);

  let homeCards = [
    {
      logo: taskLogo,
      title: "Open tasks",
      bg: "#353652", //"#abb139",
      data: data.opentasks,
      to: "/tasks/all",
      superadmin: false
    },
    {
      logo: projectLogo,
      title: "Projects",
      bg: "#643047", //"#fc8213",
      data: data.projects,
      to: "/projects",
      superadmin: false
    },
    {
      logo: usersLogo,
      title: "Users",
      bg:  "#942B3B",//"#3094cc",
      data: data.users,
      to: "/users",
      superadmin: true
    },
    {
      logo: userTag,
      title: "Customers",
      bg: "#C32530", //"#cc9530",
      data: data.customers,
      to: "/customers",
      superadmin: true
    },
  ];

  
  const getData = async (d) => {
    if(!loading) setLoadingState(true);
    const req = await axios.get(uri("/homedata"), fetchHead());
    const res = req.data.data;
    //console.log(res)
    setData({
      ...data,
      users: res.users,
      customers: res.customers,
      projects: res.projects,
      opentasks: res.opentasks
    });
    //console.log(res.recent_statuses);
    setAllStatus(res.recent_statuses);
    setStatusLikes(res.recent_statuses.map((s)=>{
      return {id: s.id, reactions: s.reactions}
    }))
    setLastStatus(res.last_status);
    setConnectedUsers(res.connected);
    setLoadingState(false);
  };

  let userMinsStatus = null;
  let userHoursStatus = null;
  if (lastStatus) {
    const diff = Math.abs(new Date(lastStatus.created_at) - new Date());
    userMinsStatus = Math.floor(diff / 1000 / 60);
    if (userMinsStatus >= 60) {
      userHoursStatus = Math.floor(userMinsStatus / 60);
      userMinsStatus = Math.floor(userMinsStatus - userHoursStatus * 60);
    }
  }

  const updateStatus = async (d) => {
    setLoadingState(true);
    const {
      status: s,
      data,
      msg,
    } = await sendData("/status/add", {
      details: !d ? stateToHTML(editorState.getCurrentContent()) : d,
      userid: currentUser.id,
    });
    if (s) {
      setEditorState(() => EditorState.createEmpty());
      toast.success("Status added", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      getData();
    } else {
      //setLoadingState(false);
      getData();
      toast.error("Error", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const reactStatus = async (id, type) => {
    if(likeLoading) return;
    setLikeLoading(true);
    const {data, msg, status} = await sendData(`/statusreactions/react`, {statusid: id, userid: currentUser.id, type});
    if(status){
      if(type == 'like'){
      setStatusLikes(statusLikes.map(sl => {
        if(sl.id == id) return {...sl, reactions: [...sl.reactions, {username: currentUser.username, userid: currentUser.id, reaction: 'like'}]}
        else return sl
      }))
    } else {
      setStatusLikes(statusLikes.map(sl => {
        if(sl.id == id) return {...sl, reactions: sl.reactions.filter(r=>r.userid != currentUser.id)}
        else return sl
      }))
    }
    } else console.log(msg)
    setLikeLoading(false);
  }

  const addComment = async (id) => {
    if(likeLoading) return;
    setLikeLoading(true);
    const status = allStatus.find(s=>s.id == id);
    if(!status.currentComment.trim()) return;
    const {data, msg, status: statusx} = await sendData(`/statuscomments/add`, {statusid: id, userid: currentUser.id, comment: status.currentComment});
    if(statusx){
      let newStatus = allStatus.map(s => s.id == id ? {...s, currentComment: "", comments: [...s.comments, {id: data.id,userid: currentUser.id, username: currentUser.username, comment: status.currentComment, first_name: currentUser.first_name, last_name: currentUser.last_name}]} : s)
      //console.log(newStatus)
      // let newAllStatus = allStatus.map((st) => st.id == s.id ? {...st, currentComment: e.target.value} : st)
      setAllStatus(newStatus)
      showToast("success", "comment added", 1000);
    }else console.log(msg)
    setLikeLoading(false)
  }

  const deleteComment = async (id, sid) =>{
    if(likeLoading) return;
    setLikeLoading(true);
    //console.log(id)
    const {data, msg, status: statusx} = await sendData(`/statuscomments/delete`, {commentid: id});
    if(statusx){
      let newStatus = allStatus.map(s => s.id == sid ? {...s, comments: s.comments.filter(c=>c.id != id)} : s)
      setAllStatus(newStatus)
      showToast("success", "comment deleted", 1000);
    }else console.log(msg)
    setLikeLoading(false)

  }

  

  useEffect(() => {
    getData();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div>
        <div className="container">

          <div className="my-4"></div>
          {/* {currentUser.superuser && ( */}
            <div className={`row gx-2 gy-4`}>
              {homeCards.map((item, i) =>  { 
                let show = true;
                if(!currentUser.superuser && item.superadmin) show = false;
                if(show) return  (
                <HomeCard
                  key={i}
                  logo={item.logo}
                  title={item.title}
                  bg={item.bg}
                  data={item.data}
                  length={item.length}
                  to={item.to}
                  index={i}
                />
              ) 
              else return null
              }
              )}
            </div>
          {/* )} */}

</div>
          
<div className="container-fluid">
<div className="mt-4 mb-4  row gx-5 justify-content-between">
  {currentUser.superuser && ( <div className="d-flex p-2 col-md-6 connected-div" style={{backgroundImage: 'linear-gradient(-20deg,#2b5876 0%,#4e4376 100%)'}}>
  <span className="my-0 ms-2 text-white">Connected Users: </span>{connectedUsers.map((u,i)=><Avatar key={i} name={`${u.first_name} ${u.last_name}`} size="20" round textSizeRatio={2.3} />)}
  </div>
    )}
    
<div className="d-flex  p-2 justify-content-between col-md-6 connected-div" style={{backgroundImage: 'linear-gradient(-20deg,#2b5876 0%,#4e4376 100%)'}}>
  <label
    className="display-6"
    style={{ fontSize: 23, color: "white" }}
  >
    Status{" "}
    <span style={{ fontSize: 16 }} className="text-muted">
      Your last update: {lastStatus ? lastStatus.created_at : null}
      {userMinsStatus &&
        `,${
          userHoursStatus ? ` ${userHoursStatus} hours &` : ""
        } ${userMinsStatus} mins ago`}
    </span>
  </label>
  <div className="d-flex align-items-center">
    <FontAwesomeIcon
      onClick={() => navigate("/status/add")}
      color="white"
      icon={faPlus}
      style={{
        marginRight: "20px",
        transform: "scale(2)",
        cursor: "pointer",
      }}
    />
    <button
      disabled={!lastStatus}
      className="btn btn-primary"
      onClick={() =>
        connected
          ? updateStatus(lastStatus.details)
          : toast.error("Not connected", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            })
      }
    >
      Continue
    </button>
  </div>
</div>
</div>
</div>


<div className="container">
          <div className="d-flex p-2 justify-content-between mt-3" style={{backgroundColor: '#242D51'}}>
            <label
              className="display-6"
              style={{ fontSize: 23, color: "white" }}
            >
              Recent status{" "}
            </label>
          </div>

          <div className="pb-5">
            {allStatus.map((s, i) => {
              const statusLike = statusLikes.find(_ => _.id == s.id)
              const reacted = statusLike.reactions.some(_ => _.userid == currentUser.id)
              let numOfLikes = 0;
              let likedBy = 'Liked By: ';
              if(statusLike){
                numOfLikes = statusLike.reactions.length
                 statusLike.reactions.forEach((r, i)=>{
                if(r.reaction == 'like') likedBy += i !== 0 ? `, ${r.username}` : r.username
              })
            }

              return (
                <div
                  key={i}
                  className="border-bottom"
                  style={{ backgroundColor: "#f5f5f5", paddingTop: i==0 ? 5 : 15 }}
                > 
                <div className="d-flex">
                <div><Avatar name={`${s.first_name} ${s.last_name}`} size="35" textSizeRatio={2} round/></div>
                <div className="ms-1">
                  <p className="m-0">
                    <span
                      style={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => navigate(`/status/${s.userid}`)}
                    >
                      {s.username}{" "}
                    </span>
                    <span
                      className="text-muted"
                      style={{ fontStyle: "italic", fontSize: 12 }}
                    >
                      ({s.created_at})
                    </span>
                    <span title="like" className="ms-2 cursor-pointer"><FontAwesomeIcon style={{opacity: likeLoading ? 0.5:1}} icon={faThumbsUp} color={reacted ? 'blue' : 'gray'} onClick={()=>reactStatus(s.id, reacted ? 'unlike':'like')}  /></span>
                    <span title={likedBy} className="ms-1" style={{fontSize: 14}} >{numOfLikes}</span>
                  </p>
                  <div
                    style={{ backgroundColor: "#f5f5f5" }}
                    className="editor-main-text"
                    dangerouslySetInnerHTML={{ __html: s.details }}
                  ></div>
                  </div>

                  </div>
                  

                  {/* comments section */}
                  {s.comments.length > 0 && (<div style={{fontSize: 14}}>
                    <p className="fst-italic m-0" style={{color:'black', fontSize: 14}}><u>Comments</u></p>

                    {s.comments.map((c, x)=>{
                      return (<div key={x} className='d-flex'>
                        <div><Avatar name={`${c.first_name} ${c.last_name}`} size="19" textSizeRatio={2.4} round/></div>
                        <div className="ms-1">
                        <p className="m-0"><b>{c.username}</b>{" "}{c.userid == currentUser.id && <span><FontAwesomeIcon role={'button'} onClick={()=>deleteComment(c.id, s.id)} icon={faTrashCan} color='red' size={'sm'} /></span>}</p>
                        <p className="m-0">{c.comment}</p>
                        </div>

                        </div>)
                    })}
                  </div>)}
                  
                  {/* comments section */}

                  {/* comments input */}
                  <div className="d-flex">
 
                    <input value={s.currentComment} className="form-control shadow-none custom-comment" style={{width: '92%'}} placeholder="add comment" type={'text'} onChange={(e)=>{
                      let newAllStatus = allStatus.map((st) => st.id == s.id ? {...st, currentComment: e.target.value} : st)
                      setAllStatus(newAllStatus)
                    }}  />
                    <div tabIndex={0} role='button' className="d-flex justify-content-center align-items-center" style={{width: '8%', backgroundColor: '#192841'}} onClick={(e)=>addComment(s.id)}>
                    <FontAwesomeIcon icon={faPaperPlane} color='white' />
                    </div>
                  </div>
                  {/* comments input */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
}

export default Home;
