import React from "react";
import { Badge } from "react-bootstrap";
import {convertMinutesToString} from '../Utils/utils'
import {  Link } from "react-router-dom";
const TaskItem = ({ task, navigate, setConfModal, setShowx, showMilestone, showProject, showJob })  => {
  return (
    <div className="table-responsive mt-2">
      <table className="table table-bordered info-tablex">
        <tbody>
          <tr>
            <td style={{ width: "30%" }}>
              <div>
                <p>
                  <b>Total Time:</b> {convertMinutesToString(task.total_time)}
                </p>
              </div>
            </td>
            <td>
              <div>
                <button className="btn btn-primary" onClick={()=>navigate(`/projects/tasks/edit/${task.id}`)}>Update</button>
                <button className="btn btn-primary mx-2" onClick={()=>setShowx(task.id)}>Add Time</button>
                <button className="btn btn-danger" onClick={()=>setConfModal({title: 'Are you sure you want to delete this task?', show: true, type: 'tasks', id: task.id})} >Delete</button>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <b>Title</b>
            </td>
            <td>{task.title}</td>
          </tr>

          <tr>
            <td>
              <b>Details</b>
            </td>
            <td className="editor-main-text" dangerouslySetInnerHTML={{ __html: task.description }}></td>
          </tr>

          <tr>
            <td>
              <b>Status</b>
            </td>
            <td>{task.status}</td>
          </tr>

          {showProject && (<tr><td><b>Project</b></td><td><Link to={`/projects/${task.projectid}`}>{task.projectname}</Link></td></tr>)}

          {showMilestone && (<tr><td><b>Milestone</b></td><td>{task.milestonetitle}</td></tr>)}

          {showJob && (<tr><td><b>Epic</b></td><td>{task.jobtitle}</td></tr>)}

          <tr>
            <td>
              <b>Assgined to</b>
            </td>
            <td><Badge pill bg="primary" className="cursor-pointer" onClick={() =>navigate(`/users/${task.userid}`)}>
                          {task.username}</Badge></td></tr>
                          
          <tr>
            <td>
              <b>Tags</b>
            </td>
            <td>{task.tags.map((tag)=>(<React.Fragment key={Math.random()}>
              <Badge pill bg="primary" className="cursor-pointer">{tag.label}</Badge>{" "}
            </React.Fragment>))}
            </td>
          </tr>


          <tr>
            <td>
              <b>Extra</b>
            </td>
            <td>{Boolean(task.extra).toString()}</td>
          </tr>

          <tr>
            <td>
              <b>Start Date</b>
            </td>
            <td>{task.starting_date}</td>
          </tr>

          <tr>
            <td>
              <b>End Date</b>
            </td>
            <td>{task.ending_date}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TaskItem;
