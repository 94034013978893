import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setConnection } from "../state/authReducer";
import {
  getCurrentUser,
  sendData,
  fetchData,
  sendNotification,
  requestNotificationPermission,
} from "../Utils/utils";
function NavBar() {
  const user = useSelector((state) => state.auth.user);
  let isAdmin = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = getCurrentUser(navigate);
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);

  if (user.username) isAdmin = user.superuser;
  else if (currentUser) isAdmin = currentUser.superuser;

  const logout = async () => {
    setLoading(true);
    const { status, data, msg } = await sendData(
      `/connections/disconnect/${currentUser.id}`,
      { disconnect_type: "logout" }
    );
    if (status) {
      localStorage.removeItem("token");
      dispatch(setUser({ username: null, superuser: false }));
      navigate("/login");
    } else {
      getLastConnectionStatus();
      setLoading(false);
    }
  };

  const SmallCircle = ({ color, animate }) => (
    <div
      className={animate ? "small-ball" : ""}
      style={{
        backgroundColor: `${color}`,
        width: 15,
        height: 15,
        borderRadius: "50%",
      }}
    ></div>
  );

  const getLastConnectionStatus = async () => {
    if (!loading) setLoading(true);
    requestNotificationPermission();
    const { status, data, msg } = await fetchData(
      `/connections/${currentUser.id}`
    );
    if (status) {
      if (data) {
        if (data.end_date) {
          dispatch(setConnection(false));
          setConnected(false);
        } else {
          //onst diff = Math.abs(new Date() - new Date(data.start_date));
          //const minDiff = Math.floor(diff / 1000 / 60);
          dispatch(setConnection(true));
          setConnected(true);
          //if (minDiff >= 25) sendNotification(`status-${data.id}`);
        }
      } else {
        dispatch(setConnection(false));
        setConnected(false);
      }
    } else {
      dispatch(setConnection(false));
      console.log(msg);
    }
    setLoading(false);
  };

  const connectDisconnect = async (type, disconnect_type) => {
    setLoading(true);
    const { status, data, msg } = await sendData(
      `/connections/${type}/${currentUser.id}`,
      { disconnect_type }
    );
    getLastConnectionStatus();
    setLoading(false);
  };

  useEffect(() => {
    getLastConnectionStatus();
    setInterval(() => {
      getLastConnectionStatus();
    }, 1000 * 60 * 2);
  }, [navigate]);

  return (
    <Navbar collapseOnSelect expand="lg"  variant="dark" style={{backgroundColor: '#242D51'}}>
      <Container>
        <Navbar.Brand
          onClick={() => navigate("/home")}
          style={{ cursor: "pointer" }}
        >
          OSINA
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate("/dailyreports")}>
              Daily Reports
            </Nav.Link>
            {isAdmin && (
              <>
                <NavDropdown title="activity">
                  <NavDropdown.Item onClick={() => navigate("/activity")}>
                    Recent activity
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => navigate("/activity/summary")}
                  >
                    Activity Summary
                  </NavDropdown.Item>
                </NavDropdown>

                {/* <Nav.Link onClick={() => {}}>Tasks</Nav.Link> 
                <Nav.Link onClick={() => navigate("/projects")}>
                  Projects
                </Nav.Link>*/}
                <Nav.Link onClick={() => navigate("/users")}>Users</Nav.Link>

                <NavDropdown title="Utils" id="collasible-nav-dropdown">
                  <NavDropdown.Item onClick={() => navigate("/projecttypes")}>
                    project types
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/countries")}>
                    countries
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/contactbooks")}>
                    contact books
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/sources")}>
                    sources
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/contactgroups")}>
                    contact groups
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/contactsm")}>
                    contact social media
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => navigate("/tags")}>
                    Tags
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}

            <Nav.Link>Account</Nav.Link>
            <Nav.Link disabled={loading} onClick={() => logout()}>
              Logout
            </Nav.Link>
          </Nav>

          <div className="d-flex align-items-center">
            <SmallCircle
              color={connected ? "green" : "red"}
              animate={connected}
            />
            <button
              disabled={loading}
              onClick={() =>
                connectDisconnect(
                  connected ? "disconnect" : "connect",
                  connected ? "choice" : null
                )
              }
              className={`shadow-none ms-2 btn btn-${
                connected ? "danger" : "success"
              }`}
            >
              {connected ? "Disconnect" : "Connect"}
            </button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
