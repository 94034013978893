import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { fetchData, sendData, splitTime } from "../../Utils/utils";
import { DateRangePicker } from "react-date-range";

const daysArr = new Array(
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
);
const monthsArr = new Array(
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
);

function getMinDiff(end_date, start_date) {
  const diff = Math.abs(new Date(end_date) - new Date(start_date));
  const minDiff = Math.floor(diff / 1000 / 60);
  return minDiff;
}

function getTimeString(hourDifference, minDifference, daysDiff) {
  return `${daysDiff ? `${daysDiff} days & ` : ""}${
    hourDifference > 0 ? `${hourDifference} hours & ` : ""
  }${minDifference} minutes`;
}

const Summary = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const userid = useRef(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userVal, setUserVal] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [error, setError] = useState(null);
  console.log(userid.current);
  const getUsers = async () => {
    const { status, data: datax, msg } = await fetchData("/users/selectdata");
    if (status) {
      setUsers(datax);
      if (datax.length > 0) {
        getData(datax[0].id, selectionRange);
        userid.current = datax[0].id;
      }
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  const getData = async (id, range) => {
    //console.log(range);
    if (!loading && !dataLoading) setDataLoading(true);
    const toSend = {
      start_date: range.startDate.getTime(),
      end_date: range.endDate.getTime(),
    };
    const {
      status,
      data: datax,
      msg,
    } = await sendData(`/connections/allwithrange/${id}`, toSend);
    if (status) {
      //console.log(datax);
      if (datax.length > 0) {
        //let mainDate = { totalTime: 0, days: [] };
        const withEndDates = datax.filter((d) => d.end_date != null);
        //!---
        let minDifference = withEndDates.reduce(
          (t, c) => t + getMinDiff(c.end_date, c.start_date),
          0
        );
        var hourDifference = Math.floor(minDifference / 60);
        var dayDifference = Math.floor(hourDifference / 24);

        hourDifference = hourDifference - dayDifference * 24;
        minDifference =
          minDifference - dayDifference * 24 * 60 - hourDifference * 60;

        //!---
        let daysDraft = withEndDates.map((d) => d.start_date.slice(0, 10));
        let uniqueDays = [...new Set(daysDraft)];
        let daysx = uniqueDays.map((d) => ({ date: d, cons: [] }));
        daysx.sort((a, b) => new Date(a.date) - new Date(b.date));
        withEndDates.forEach((d) => {
          const index = daysx.findIndex(
            (x) => x.date == d.start_date.slice(0, 10)
          );
          if (index != -1) {
            daysx[index].cons.push(d);
          }
        });
        daysx.forEach((d) => {
          let md = d.cons.reduce(
            (t, c) => t + getMinDiff(c.end_date, c.start_date),
            0
          );
          let hd = 0;
          if (md >= 60) {
            hd = Math.floor(md / 60);
            md = Math.floor(md - hd * 60);
          }
          d["totalTime"] = md;
          d["totalString"] = getTimeString(hd, md);
        });
        //console.log(daysx);
        setData({
          totalTime: minDifference,
          days: daysx,
          totalString: getTimeString(
            hourDifference,
            minDifference,
            dayDifference
          ),
        });
      } else setData(null);
    } else console.log(msg);
    if (loading) setLoading(false);
    else setDataLoading(false);
  };

  const handleDateChange = (ranges) => {
    setSelectionRange(ranges.selection);
    getData(userid.current, ranges.selection);
  };

  useEffect(() => {
    getUsers();
  }, []);

  if (loading) return <Loading div={false} />;
  else
    return (
      <div className="container">
        <h1 className="display-5">Activity Summary</h1>
        <div className="mt-2">
          <label>Select user</label>
          <select
            onChange={(e) => {
              userid.current = e.target.value;
              setUserVal(e.target.value);
              getData(e.target.value, selectionRange);
            }}
            className="form-control"
            value={userVal}
          >
            {users.map((u) => (
              <option key={Math.random()} value={u.id}>
                {u.username}
              </option>
            ))}
          </select>

          <label className="mt-4">Select date range</label>
          <br />
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleDateChange}
          />
          {dataLoading ? (
            <Loading div={true} />
          ) : !data ? (
            <p className="mt-3" style={{ fontWeight: "bold" }}>
              No Activity for this user in the selected date range
            </p>
          ) : (
            <div className="mt-3">
              <p>
                <span style={{ fontWeight: "bold" }}>Total time worked:</span>{" "}
                {data.totalString}
              </p>
              <p style={{ textDecoration: "underline" }}>Days information:</p>
              <div className="my-3">
                {data.days.map((day) => {
                  const d = new Date(day.date);
                  const dayStr = daysArr[d.getDay()];
                  const dayNum = d.getDate();
                  const month = monthsArr[d.getMonth()];
                  const year = d.getFullYear();
                  return (
                    <p key={Math.random()}>
                      <span style={{ fontWeight: "bold" }}>
                        {dayStr}, {dayNum} {month}, {year}:
                      </span>{" "}
                      {day.totalString}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default Summary;
