import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fetchHead from "../Utils/fetchHead";
import uri from "../Utils/uri";
import Loading from "../components/Loading";
import ErrorBox from "../components/ErrorBox";
import ConfirmModal from "../components/ConfirmModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ContactGroupItem from "../components/ContactGroupItem";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import Select from "react-select";

const ContactGroups = () => {
  //const navigate = useNavigate();
  const currID = useRef(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalErr, setModalErr] = useState(null);
  const [name, setName] = useState("");
  const [selectValues, setSelectValues] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState(data);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const getContactGroups = async () => {
    try {
      setError(null);
      const URI = uri("/contactgroups");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setData(res.data.contactgroups);
        setSelectValues(res.data.contactbooks);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("An error has occured");
      setLoading(false);
    }
  };

  const addContactGroup = async (body) => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const dataToSend = {
        contacts: body.map((i) => ({ id: i.value })),
        name,
      };
      const URI = uri("/contactgroups/add");
      const req = await axios.post(URI, dataToSend, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact group added", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setModal(false);
        getContactGroups();
      } else {
        setModalErr(res.data);
        setLoading(false);
      }
    } catch (err) {
      setModalErr("An error has occured!");
      setLoading(false);
    }
  };

  const deleteContactGroup = async () => {
    try {
      setLoading(true);
      setError(null);
      setModalErr(null);
      const req = await axios.delete(
        uri(`/contactgroups/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact Group deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getContactGroups();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  const editContactGroup = async (name, newContacts, id, cg) => {
    try {
      setLoading(true);
      setError(null);
      let toDelete = [];
      let toAdd = [];
      cg.contacts.forEach((item, i) => {
        const remained = newContacts.some((x) => x.value == item.id);

        if (!remained) toDelete.push({ ...item, type: "delete" });
      });
      newContacts.forEach((item, i) => {
        const remained = cg.contacts.some((x) => x.id == item.value);
        if (!remained) toAdd.push({ ...item, type: "add" });
      });
      const finalContacts = [...toAdd, ...toDelete];
      let newData = {
        contacts: finalContacts,
        name,
      };
      const req = await axios.put(
        uri(`/contactgroups/${id}`),
        newData,
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact group edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        getContactGroups();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("An Error has occured!");
      setLoading(false);
    }
  };

  const close = () => setModal(false);

  const RenderModal = () => {
    return (
      <Modal
        show={modal}
        onHide={close}
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <p>Add Contact group</p>
          {modalErr && <ErrorBox err={modalErr} type="danger" />}
          <Form.Label>Name</Form.Label>
          <InputGroup>
            <input
              value={name}
              type={"text"}
              className="form-control"
              onChange={(e) => setName(e.target.value)}
            />
          </InputGroup>
          <Form.Label className="mt-3">Contacts</Form.Label>
          <Select
            options={selectValues}
            isMulti
            onChange={(values) => {
              setContacts(values);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={close}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
          <Button
            disabled={!name.trim()}
            variant="success"
            onClick={() => {
              addContactGroup(contacts);
            }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  useEffect(() => {
    getContactGroups();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      {RenderModal()}
      <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false })}
        onConfirm={() => deleteContactGroup()}
      />

      <p className="display-5 text-center">Contact Groups</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary shadow-none"
          onClick={() => setModal(true)}
        >
          Add Contact Group
        </button>
      </div>
      <div className="table-responsive" style={{ paddingBottom: "10%" }}>
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">contacts</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              return (
                <ContactGroupItem
                  i={i}
                  data={d}
                  setConfModal={setConfModal}
                  key={i}
                  editContactGroup={editContactGroup}
                  selectValues={selectValues}
                  currID={currID}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactGroups;
