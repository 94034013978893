import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Loading from "../../../components/Loading";
import ErrorBox from "../../../components/ErrorBox";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fetchData, sendData, formatDate, showToast } from "../../../Utils/utils";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Editor from '../../../components/Editor';
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const AddJob = () => {
  const { register, handleSubmit, formState, control, setValue } = useForm({
    defaultValues: {
        start_date: new Date(),
        end_date: null
    }
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const milestones = useRef(null);
  const [editorState, setEditorState] = useState(() =>EditorState.createEmpty());
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();
  const {state} = location;
  
  const getMilestones = async () => {
    const {status, data, msg} = await fetchData(`/milestones/${id}/options`);
    if(status) {
        console.log(data)
        if(data.length > 0) milestones.current = data
    } else setError(msg)
    setLoading(false);
  }

  const addJob = async (datax) => {
    setLoading(true);
    const dataToSend = {...datax, status:datax.status.value , milestone: datax.milestone.value, 
      starting_date: formatDate(datax.start_date), ending_date: datax.end_date ? formatDate(datax.end_date):null,
      description: stateToHTML(editorState.getCurrentContent()).replace(/'/g, "''")}
    if (error) setError(null);
    const { status, data, msg } = await sendData("/epics/add", dataToSend);
    if (status) {
      showToast("success", "Epic added")
      navigate(`/projects/${id}`);
    } else { setError(msg);
    setLoading(false);
    }
  };

  useEffect(()=>{
    getMilestones();
  },[])

  if (loading) return <Loading />;
  else
    return (
        <div className="container" style={{ paddingBottom: "100px" }}>
        <p className="display-5 text-center">Add Epic</p>
        {error && <ErrorBox type={"warning"} err={error} />}
        <form className="mt-4" onSubmit={handleSubmit(addJob)}>
          <label className="form-label">Title</label>
          <input
            {...register("title", { required: true })}
            className="form-control"
          />

          <label className="form-label mt-4">Description</label>
          <Editor editorState={editorState} setEditorState={setEditorState} />

          <label className="form-label mt-4">Status</label>
          <Controller
        name="status"
        rules={{required: true}}
        control={control}
        render={({ field }) => <Select 
          {...field} 
          options={[
            { value: "In Progress", label: "In Progress" },
            { value: "Closed", label: "Closed" },
          ]} 
        />}
      />

        <label className="form-label mt-4">Milestone</label>
          <Controller
        name="milestone"
        rules={{required: true}}
        control={control}
        defaultValue={state ? {value: state.milestoneid, label: state.milestonename } : null}
        render={({ field }) => <Select 
          {...field} 
          options={milestones.current} 
        />}
      />

        <label className="form-label mt-4">Starting Date</label>
        <Controller
                name={'start_date'}
                control={control}
                rules={{required: true}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("start_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

<label className="form-label mt-4">Ending Date</label>
        <Controller
                name={'end_date'}
                control={control}
                rules={{required: false}}
                render={({ onChange, value, field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(d)=>setValue("end_date", d)}
                        showTimeSelect
                        dateFormat="Pp"
                        className="form-control"
                        showYearDropdown
                        scrollableYearDropdown
                        isClearable
                    />
                )}
            />

          <input className="btn btn-primary mt-5" type={"submit"} value="Add Epic" />
        </form>
      </div>
    );
};

export default AddJob;
