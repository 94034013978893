import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import fetchHead from "../../Utils/fetchHead";
import uri from "../../Utils/uri";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import ErrorBox from "../../components/ErrorBox";
import ConfirmModal from "../../components/ConfirmModal";
import ContactBookItem from "../../components/ContactBookItem";
const ContactBooks = () => {
  const navigate = useNavigate();
  const currID = useRef(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confModal, setConfModal] = useState({ title: "", show: false });
  const [data, setData] = useState([]);

  const getContactBooks = async () => {
    try {
      setError(null);
      const URI = uri("/contactbooks");
      const req = await axios.get(URI, fetchHead());
      const res = req.data;
      if (res.status == 200) {
        setData(res.data);
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured");
      setLoading(false);
    }
  };

  const deleteContactBook = async () => {
    try {
      setLoading(true);
      setError(null);
      const req = await axios.delete(
        uri(`/contactbooks/${currID.current}`),
        fetchHead()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact book deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setConfModal({ title: "", show: false });
        getContactBooks();
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getContactBooks();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  return (
    <div className="container">
      <ConfirmModal
        title={confModal.title}
        show={confModal.show}
        onHide={() => setConfModal({ title: "", show: false })}
        onConfirm={() => deleteContactBook()}
      />
      <p className="display-5 text-center">Contact Books</p>
      <div className="d-flex">
        <button
          className="btn btn-outline-primary shadow-none"
          onClick={() => navigate("/contactbooks/add")}
        >
          Add Contact Book
        </button>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered mt-3 table-striped">
          <thead>
            <tr>
              <th scope="col">id</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Country</th>
              <th scope="col">Groups</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => {
              return (
                <ContactBookItem
                  showConfModal={() => {
                    currID.current = d.id;
                    setConfModal({
                      title: `Are you sure you want to delete the contact: ${d.first_name} ${d.last_name}`,
                      show: true,
                    });
                  }}
                  key={i}
                  contactbook={d}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContactBooks;
