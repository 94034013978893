import React from "react";

const UserActivity = ({ activity }) => {
  return (
    <div className="border-bottom">
      <p>{activity.username}</p>
    </div>
  );
};

export default UserActivity;
