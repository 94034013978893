import React from "react";
import { useNavigate } from "react-router-dom";
const HomeCard = ({ logo, data, title, bg, to, index }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`col-md-3`}>
        <div
          onClick={() => navigate(to)}
          className={`homecard w-75 m-auto`}
          style={{ backgroundColor: bg, boxShadow:'0 0.46875rem 2.1875rem rgba(4,9,20,.03), 0 0.9375rem 1.40625rem rgba(4,9,20,.03), 0 0.25rem 0.53125rem rgba(4,9,20,.05), 0 0.125rem 0.1875rem rgba(4,9,20,.03)' }}
        >
          <div className=" h-100 px-3 card-divx">
            <p className="text-white display-6 h-50 text-break m-0" style={{fontSize:'50px'}}>{data}</p>
            <div className="h-50 text-white p-0 m-0">
              <p className="m-0 p-0 ">{title}</p>
              <p className="m-0 p-0" style={{textDecoration:'underline', letterSpacing:'1px'}}>see more</p>
            </div>
          </div>
          <div className=" h-100 d-flex justify-content-center align-items-center card-imgx">
            <img src={logo} alt="tasks" className="w-75" />
          </div>
        </div>
      </div>
      {/* {index != 2 && <div className="col-md-1"></div>} */}
    </>
  );
};

export default HomeCard;
