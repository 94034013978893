import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Users from "./pages/User/Users";
import store from "./state/store";
import UserDetails from "./pages/User/UserDetails";
import CreateUser from "./pages/User/CreateUser";
import Projects from "./pages/Projects/Projects";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import Tasks from "./pages//Projects/Tasks/Tasks";
import ProjectTypes from "./pages/ProjectTypes";
import Countries from "./pages/Countries";
import ContactBooks from "./pages/Contactbook/ContactBooks";
import AddContactBook from "./pages/Contactbook/AddContactBook";
import ContactBookDetails from "./pages/Contactbook/ContactBookDetails";
import Sources from "./pages/Sources";
import Customers from "./pages/Customer/Customers";
import CreateCustomer from "./pages/Customer/CreateCustomer";
import CustomerDetails from "./pages/Customer/CustomerDetails";
import ContactGroups from "./pages/ContactGroups";
import ContactSm from "./pages/ContactSm";
import AddProject from "./pages/Projects/AddProject";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthedRoute from "./components/AuthedRoute";
import DailyReports from "./pages/dailyreports/DailyReports";
import AddDailyReport from "./pages/dailyreports/AddDailyReport";
import AddStatus from "./pages/Status/AddStatus";
import Status from "./pages/Status/Status";
import Activity from "./pages/activity/Activity";
import UserActivity from "./pages/activity/UserActivity";
import Summary from "./pages/activity/Summary";
import EditMilestone from "./pages/Projects/Milestones/EditMilestone";
import AddMilestone from "./pages/Projects/Milestones/AddMilestone";
import AddJob from "./pages/Projects/Jobs/AddJob";
import EditJob from "./pages/Projects/Jobs/EditJob";
import AddTask from "./pages/Projects/Tasks/AddTask";
import EditTask from "./pages/Projects/Tasks/EditTask";
import Tags from "./pages/Tags";
import EditProject from "./pages/Projects/EditProject";
import Milestones from "./pages/Projects/Milestones/Milestones";
import Jobs from "./pages/Projects/Jobs/Jobs";
import Tasksx from "./pages/Tasks";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<AuthedRoute component={Login} />} />
          <Route path="/home" element={<ProtectedRoute component={Home} />} />
          <Route
            path="/users"
            element={<ProtectedRoute component={Users} superadmin={true} />}
          />
          <Route
            path="/users/:id"
            element={
              <ProtectedRoute component={UserDetails} superadmin={true} />
            }
          />
          <Route
            path="/users/create"
            element={
              <ProtectedRoute component={CreateUser} superadmin={true} />
            }
          />
          <Route
            path="/status/:id"
            element={<ProtectedRoute component={Status} />}
          />
          <Route
            path="/status/add"
            element={<ProtectedRoute component={AddStatus} />}
          />
          <Route
            path="/dailyreports"
            element={<ProtectedRoute component={DailyReports} />}
          />
          <Route
            path="/dailyreports/add"
            element={<ProtectedRoute component={AddDailyReport} />}
          />
          <Route
            path="/activity"
            element={<ProtectedRoute component={Activity} superadmin={true} />}
          />
          <Route
            path="/activity/:id"
            element={
              <ProtectedRoute component={UserActivity} superadmin={true} />
            }
          />
          <Route
            path="/activity/summary"
            element={<ProtectedRoute component={Summary} superadmin={true} />}
          />

          <Route
            path="/users/create"
            element={<ProtectedRoute component={CreateUser} superadmin={true} />}
          />


            <Route
              path="/tasks/all"
              element={<ProtectedRoute superadmin={false} component={Tasksx} />}
            />
                      
            <Route
              path="/projects"
              element={<ProtectedRoute superadmin={false} component={Projects} />}
            />

            

            <Route
              path="/projects/add"
              element={
                <ProtectedRoute superadmin={true} component={AddProject} />
              }
            />

            <Route
              path="/projects/:id"
              element={
                <ProtectedRoute superadmin={false} component={ProjectDetails} />
              }
            />

            <Route
              path="/projects/:id/edit"
              element={
                <ProtectedRoute superadmin={true} component={EditProject} />
              }
            />

            <Route
              path="/projects/:id/milestones/add"
              element={
                <ProtectedRoute superadmin={false} component={AddMilestone} />
              }
            />
            <Route
              path="/projects/milestones/edit/:id"
              element={
                <ProtectedRoute superadmin={false} component={EditMilestone} />
              }
            />
            <Route
              path="/projects/:id/milestones"
              element={
                <ProtectedRoute superadmin={false} component={Milestones} />
              }
            />
            <Route
              path="/projects/:id/jobs/add"
              element={
                <ProtectedRoute superadmin={false} component={AddJob} />
              }
            />
            <Route
              path="/projects/jobs/edit/:id"
              element={
                <ProtectedRoute superadmin={false} component={EditJob} />
              }
            />
            <Route
              path="/projects/:id/jobs"
              element={
                <ProtectedRoute superadmin={false} component={Jobs} />
              }
            />
            <Route
              path="/projects/:id/tasks"
              element={
                <ProtectedRoute superadmin={false} component={Tasks} />
              }
            />
            <Route
              path="/projects/:id/tasks/add"
              element={
                <ProtectedRoute superadmin={false} component={AddTask} />
              }
            />
            <Route
              path="/projects/tasks/edit/:id"
              element={
                <ProtectedRoute superadmin={false} component={EditTask} />
              }
            />
            <Route
              path="/tasks"
              element={<ProtectedRoute superadmin={true} component={Tasks} />}
            />
            <Route
              path="/projecttypes/"
              element={
                <ProtectedRoute superadmin={true} component={ProjectTypes} />
              }
            />
            <Route
              path="/countries/"
              element={
                <ProtectedRoute superadmin={true} component={Countries} />
              }
            />
            <Route
              path="/contactbooks"
              element={
                <ProtectedRoute superadmin={true} component={ContactBooks} />
              }
            />
            <Route
              path="/contactbooks/add"
              element={
                <ProtectedRoute superadmin={true} component={AddContactBook} />
              }
            />
            <Route
              path="/contactbooks/:id"
              element={
                <ProtectedRoute
                  superadmin={true}
                  component={ContactBookDetails}
                />
              }
            />
            <Route
              path="/sources"
              element={<ProtectedRoute superadmin={true} component={Sources} />}
            />
            <Route
              path="/customers"
              element={
                <ProtectedRoute superadmin={true} component={Customers} />
              }
            />
            <Route
              path="/customers/create"
              element={
                <ProtectedRoute superadmin={true} component={CreateCustomer} />
              }
            />
            <Route
              path="/customers/:id"
              element={
                <ProtectedRoute superadmin={true} component={CustomerDetails} />
              }
            />
            <Route
              path="/contactgroups"
              element={
                <ProtectedRoute superadmin={true} component={ContactGroups} />
              }
            />
            <Route
              path="/contactsm"
              element={
                <ProtectedRoute superadmin={true} component={ContactSm} />
              }
            /> 
            <Route
              path="/tags"
              element={
                <ProtectedRoute superadmin={true} component={Tags} />
              }
            /> 
            <Route
              path="*"
              element={<AuthedRoute  component={Login} />}
            />

            <Route path="*" element={<Navigate to={"/home"} replace />} />

        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
