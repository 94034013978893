import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { fetchData, getCurrentUser } from "../../Utils/utils";
const Status = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currentUser = getCurrentUser(navigate);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllUserStatus = async () => {
    let URI = `/status/allstatus/${id}/1`;
    if (!currentUser.superuser && currentUser.id != id)
      URI = `/status/allstatus/${id}/0`;
    const { status, data, msg } = await fetchData(URI);
    if (status) {
      setData(data);
      setLoading(false);
    } else navigate("/home", { replace: true });
  };

  useEffect(() => {
    getAllUserStatus();
  }, []);

  if (loading) return <Loading />;
  else
    return (
      <div className="container">
        <h1 className="display-6">
          {data.length > 0
            ? data[0].username
            : "This user has no status updates today or you don't have the permission to view all of this user's status history"}
        </h1>
        <div className="pb-4">
          {data.map((s, i) => {
            return (
              <div
                key={i}
                className="border-bottom"
                style={{ backgroundColor: "#f5f5f5" }}
              >
                <p className="m-0">
                  <span style={{ fontWeight: "bold" }}>{s.username} </span>
                  <span
                    className="text-muted"
                    style={{ fontStyle: "italic", fontSize: 12 }}
                  >
                    ({s.created_at})
                  </span>
                </p>
                <div
                  style={{ backgroundColor: "#f5f5f5" }}
                  className="editor-main-text"
                  dangerouslySetInnerHTML={{ __html: s.details }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    );
};

export default Status;
