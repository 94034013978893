import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal";
import uri from "../../Utils/uri";
import Loading from "../../components/Loading";
import ErrorBox from "../../components/ErrorBox";
import fetchHeader from "../../Utils/fetchHead";
import Select from "react-select";
import { toast } from "react-toastify";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faUser,
  faMobileScreen,
  faPhone,
  faEnvelope,
  faEarthAfrica,
  faUsers,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
const ContactBookDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState({
    show: false,
    title: "",
  });
  const [contactbook, setContactbook] = useState(null);
  const [editedContactBook, setEditedContactbook] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [smOptions, setSmOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const getContactBook = async () => {
    try {
      const req = await axios.get(uri(`/contactbooks/${id}`), fetchHeader());
      const res = req.data;
      if (res.status == 200) {
        setContactbook(res.data.contactbook);
        setEditedContactbook(res.data.contactbook);
        setCountryOptions(
          res.data.countries.map((i) => ({ value: i.id, label: i.name }))
        );
        setGroupOptions(
          res.data.groups.map((i) => ({ value: i.id, label: i.name }))
        );
        setSmOptions(
          res.data.social_medias.map((i) => ({ value: i.id, label: i.name }))
        );
        setLoading(false);
      } else {
        setError(res.data);
        setLoading(false);
      }
    } catch (err) {
      setError("An error has occured. Please try again!");
      setLoading(false);
    }
  };

  const deleteContactBook = async () => {
    try {
      setLoading(true);
      setModal({ show: false, title: "" });
      const req = await axios.delete(
        uri(`/contactbooks/${contactbook.id}`),
        fetchHeader()
      );
      const res = req.data;
      if (res.status == 200) {
        toast.success("Contact deleted", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      } else {
        toast.error(res.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  const finishEditing = async () => {
    try {
      setLoading(true);
      setModal({ show: false, title: "" });
      let { id, countrycode, countryname, ...newUser } = {
        ...editedContactBook,
      };
      for (const property in newUser) {
        if (property == "") newUser[property] = null;
      }
      let toDelete = [];
      let toAdd = [];
      contactbook.groups.forEach((item, i) => {
        const remained = newUser.groups.some((x) => x.value == item.value);
        if (!remained) toDelete.push({ ...item, type: "delete" });
      });
      newUser.groups.forEach((item, i) => {
        const remained = contactbook.groups.some((x) => x.value == item.value);
        if (!remained) toAdd.push({ ...item, type: "add" });
      });
      const finalContacts = [...toAdd, ...toDelete];

      let toDeleteSm = [];
      let toAddSm = [];
      contactbook.social_medias.forEach((item, i) => {
        const remained = newUser.social_medias.some(
          (x) => x.value == item.value
        );
        if (!remained) toDelete.push({ ...item, type: "delete" });
      });
      newUser.social_medias.forEach((item, i) => {
        const remained = contactbook.social_medias.some(
          (x) => x.value == item.value
        );
        if (!remained) toAdd.push({ ...item, type: "add" });
      });
      const finalContactsSm = [...toAddSm, ...toDeleteSm];

      newUser.groups = finalContacts;
      newUser.social_medias = finalContactsSm;
      console.log({ newUser });
      const req = await axios.put(
        uri(`/contactbooks/${contactbook.id}`),
        newUser,
        fetchHeader()
      );
      const res = req.data;

      if (res.status == 200) {
        toast.success("Contact edited", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      } else {
        toast.error(res.data, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.error("Error", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getContactBook();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container">
        <ConfirmModal
          show={modal.show}
          onHide={() => setModal({ title: "", show: false })}
          title={modal.title}
          onConfirm={
            modal.title.includes("delete") ? deleteContactBook : finishEditing
          }
        />
        <p className="display-5 text-center">Contact</p>
        <div className="container" style={{ paddingBottom: 100 }}>
          <b>Information</b>
          <ul className="list-group list-group-flush mt-1">
            <li className="list-group-item">
              <FontAwesomeIcon icon={faFingerprint} /> <b>id</b>:{" "}
              {contactbook.id}
            </li>

            <li className="list-group-item">
              <FontAwesomeIcon icon={faUser} /> <b>first name</b>:{" "}
              {contactbook.first_name}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.first_name = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={editedContactBook.first_name}
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faUser} /> <b>middle name</b>:{" "}
              {contactbook.middle_name}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.middle_name = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={
                  editedContactBook.middle_name
                    ? editedContactBook.middle_name
                    : ""
                }
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faUser} /> <b>last name</b>:{" "}
              {contactbook.last_name}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.last_name = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={editedContactBook.last_name}
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faMobileScreen} /> <b>mobile</b>:{" "}
              {contactbook.mobile
                ? `+${contactbook.countrycode} ${contactbook.mobile}`
                : ""}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.mobile = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={editedContactBook.mobile ? editedContactBook.mobile : ""}
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faMobileScreen} /> <b>backup mobile</b>:{" "}
              {contactbook.backup_mobile
                ? `+${contactbook.countrycode} ${contactbook.backup_mobile}`
                : ""}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.backup_mobile = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={
                  editedContactBook.backup_mobile
                    ? editedContactBook.backup_mobile
                    : ""
                }
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faPhone} /> <b>phone</b>:{" "}
              {contactbook.phone}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.phone = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={editedContactBook.phone ? editedContactBook.phone : ""}
                className="form-control"
                type={"text"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faEnvelope} /> <b>email</b>:{" "}
              {contactbook.email}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.email = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={editedContactBook.email ? editedContactBook.email : ""}
                className="form-control"
                type={"email"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faEnvelope} /> <b>backup email</b>:{" "}
              {contactbook.backup_email}
            </li>
            {edit && (
              <input
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.backup_email = e.target.value;
                  setEditedContactbook(newUser);
                }}
                value={
                  editedContactBook.backup_email
                    ? editedContactBook.backup_email
                    : ""
                }
                className="form-control"
                type={"email"}
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faEarthAfrica} /> <b>country</b>:{" "}
              {contactbook.countryname}
            </li>
            {edit && (
              <Select
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  if (e) newUser.country = e.value;
                  else newUser.country = null;
                  setEditedContactbook(newUser);
                }}
                options={countryOptions}
                isClearable
                defaultValue={
                  contactbook.country
                    ? {
                        value: contactbook.country,
                        label: contactbook.countryname,
                      }
                    : null
                }
                className="form-control"
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faShareNodes} /> <b>Social Media</b>:{" "}
              {contactbook.social_medias.map((c, i) => {
                return (
                  <React.Fragment key={i}>
                    <Badge pill bg="primary">
                      {c.name}
                    </Badge>{" "}
                  </React.Fragment>
                );
              })}
            </li>
            {edit && (
              <Select
                isMulti
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.social_medias = e;
                  setEditedContactbook(newUser);
                }}
                options={smOptions}
                defaultValue={contactbook.social_medias.map((grp) => ({
                  value: grp.id,
                  label: grp.name,
                }))}
                className="form-control"
              />
            )}

            <li className="list-group-item">
              <FontAwesomeIcon icon={faUsers} /> <b>groups</b>:{" "}
              {contactbook.groups.map((c, i) => {
                return (
                  <React.Fragment key={i}>
                    <Badge pill bg="primary">
                      {c.name}
                    </Badge>{" "}
                  </React.Fragment>
                );
              })}
            </li>
            {edit && (
              <Select
                isMulti
                onChange={(e) => {
                  let newUser = { ...editedContactBook };
                  newUser.groups = e;
                  setEditedContactbook(newUser);
                }}
                options={groupOptions}
                defaultValue={contactbook.groups.map((grp) => ({
                  value: grp.id,
                  label: grp.name,
                }))}
                className="form-control"
              />
            )}
          </ul>
          <div className="d-flex">
            <button
              className="btn btn-warning mt-3 shadow-none"
              onClick={() => {
                if (edit)
                  setModal({
                    show: true,
                    title: `Are you sure you want to edit ${
                      contactbook.first_name
                    } ${contactbook.last_name ? contactbook.last_name : ""}`,
                  });
                else setEdit(true);
              }}
            >
              {edit ? "Finish editing" : "Edit"}
            </button>
            <button
              className="btn btn-danger mt-3 mx-2"
              onClick={() =>
                setModal({
                  show: true,
                  title: `Are you sure you want to delete ${
                    contactbook.first_name
                  } ${contactbook.last_name ? contactbook.last_name : ""}`,
                })
              }
            >
              Delete Contact
            </button>
          </div>
          {edit && (
            <button
              className="btn btn-primary mt-3"
              onClick={() => {
                setEdit(false);
                setEditedContactbook(contactbook);
              }}
            >
              Cancel edit
            </button>
          )}
        </div>
      </div>
    );
};

export default ContactBookDetails;
