import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import UserItem from "../../components/UserItem";
import ErrorBox from "../../components/ErrorBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { fetchData } from "../../Utils/utils";
//const URL = uri("/users");
const Users = () => {
  const [users, setUsers] = useState([]);
  const [initData, setInitData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [sortedBy, setSortedBy] = useState({
    date_joined: "DESC",
    last_login: "ASCE",
  });
  const navigate = useNavigate();

  const getUsers = async () => {
    //const req = await axios.get(URL, fetchHead());
    //const res = req.data;
    const { status, data, msg } = await fetchData("/users");
    if (status) {
      const myUsers = data.filter((i) => i.username != user.username);
      setUsers(myUsers);
      setInitData(myUsers);
      setLoading(false);
    } else {
      setError(msg);
      setLoading(false);
    }
  };

  const sortData = (type) => {
    if (type == "date_joined") {
      setSortedBy({
        ...sortedBy,
        date_joined: sortedBy.date_joined == "DESC" ? "ASCE" : "DESC",
      });
      setUsers(initData.reverse());
    } else {
      const sortType = sortedBy.last_login;
      setSortedBy({
        ...sortedBy,
        last_login: sortType == "DESC" ? "ASCE" : "DESC",
      });
      let usersx = users;
      if (sortType == "ASCE")
        usersx.sort((a, b) => new Date(b.last_login) - new Date(a.last_login));
      else
        usersx.sort((a, b) => new Date(a.last_login) - new Date(b.last_login));
      setUsers(usersx);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  if (loading) return <Loading />;
  else if (error) return <ErrorBox err={error} type="danger" />;
  else
    return (
      <div className="container">
        <p className="display-5 text-center">Users</p>
        <div className="d-flex">
          <button
            className="btn btn-outline-primary"
            onClick={() => navigate("/users/create")}
          >
            Add User
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered mt-3 table-striped">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Username</th>
                <th scope="col">Name</th>
                <th scope="col">
                  Date joined{" "}
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    onClick={() => sortData("date_joined")}
                    icon={faSort}
                  />
                </th>
                <th scope="col">
                  Last Login{" "}
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    onClick={() => sortData("last_login")}
                    icon={faSort}
                  />
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {users.map((item, i) => {
                return <UserItem user={item} key={i} />;
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default Users;
